.App {
    text-align: center;
  }

  .font-18{
    font-size: 1.125rem;
  }

  .font-20{
    font-size: 1.25rem;
  }

  .mb-233{
    margin-bottom: 153px
  }

.btn{
    height: 56px;
    width: 331px;
    overflow: visible;
    border-radius: 8px;
    white-space: pre;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    text-align: center;
    cursor:pointer;
  }

  .btn-regular{
      width:331px
  }

  .btn-big{
      width:505px
  }
  
  .btn:focus{
    outline:none !important;
    outline-offset: none !important;
  }

  .top-card{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    height: fit-content;

z-index: 2;
  }

  .mr-50{
    margin-right: 50px
  }

  .mt-75{
    margin-top: 75px
  }

  .top-section{
    z-index: 2;
    width: 100%;
    text-align: center;
    height: fit-content;
    align-self: center;
    margin-top: 114px;
    padding-bottom: 20px
  }


  .content-center{
    justify-content: center;
  }

  .w-full{
    width: 100%;
    max-width: none!important;
  }
  .btn-home-big{
    width: 330px!important;
    padding-top: 16px!important;
    padding-bottom: 16px!important
  }

  .title-txt{
    width: 100%!important
  }
  
  .btn-solid{
    background-color: var(--Primary_Red, #00509D);
    border:1px solid var(--Primary_Red, #00509D);
    color: var(--White, #ffffff);
  }
  
  .btn-light{
    color: var(--Primary_Red, #00509D);
    border:1px solid var(--Primary_Red, #00509D);
    background-color: transparent;
  }

  .btn-blue{
    background-color: var(--Primary_Red, #00509D);
    border:1px solid var(--Primary_Red, #00509D);
    color: var(--White, #ffffff);
  }

  .btn-light-blue{
    color: var(--Primary_Red, #00509D);
    border:1px solid var(--Primary_Red, #00509D);
    background-color: var(--White, #ffffff);
  }

  .btn-solid:hover:enabled{
    background-color: #00509D;
    border:1px solid  #00509D;
    color: var(--White, #ffffff);
  }

  .btn-light-blue:hover:enabled{
    background-color: var(--Primary_Red, #00509D);
    color: var(--White, #ffffff);
  }
  
  .btn-blue:hover:enabled{
    background-color: #00509D;
    border:1px solid  #00509D;
    color: var(--White, #ffffff);
  }
  
  /* .btn-light:hover:enabled{
    background-color: var(--Primary_Red, #00509D);
    color: var(--White, #ffffff);
  } */

  .btn:disabled{
      opacity: 0.5;
      cursor: not-allowed;
  }
  
  .mb-2{
    margin-bottom:2rem;
  }

  /****** HOME STYLES ******/
  .d-flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .left-wrap{
    width: 50%;
    align-self: center
  }

  .rigth-wrap{
    width: 50%;
    height: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
  }

  .max-width{
    width: 100%;
    height: calc(100vh - 120px);
    padding-top: 120px;
  }

  .btn-wrap{
    display: flex;
    flex-direction: column;
    width: 331px;
    align-self: center;
    margin-top: 258px
  }

  .mt-16{
    margin-top: 16px;
  }

  .mr-32{
    margin-right: 32px;
  }

  .navbar{
    z-index: 100;
  }

  .mt-single{
    margin-top: 50px;
    font-family: 'Poppins';
  }

  .pb-0{
    padding-bottom: 0!important
  }

  .home-login-wrap{
    display: flex;
    flex-direction: column;
    width: fit-content!important;
    padding: 48px 54px!important;
    border: 6px solid rgba(56, 23, 201, 0.1);
border-radius: 12px;
  }

  @media(max-width: 480px){
    .login-mo{
      display: none;
    }
    
    .btn-home-big{
      margin-right: 0px;
        font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px!important;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.5px;
align-self: center;

/* White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
        height: 54px!important;
        width: 290px!important
    }

    .home-login-wrap{
      padding: 48px 20px !important;
      width: 290px!important;
      margin-top: 48px!important
    }

    .login-home-title{
      /* Headline medium | Oxygen */

font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 24px!important;
line-height: 30px!important;
/* or 125% */

text-align: center;

/* Soft Black */

color: #2F2E41;
width: 267px!important;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
margin-bottom: 0px!important
    }

    .login-footer-relative{
      font-family: 'Oxygen';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */
      
      text-align: center;
      letter-spacing: 0.25px;
      
      /* Soft black | 50 */
      
      color: rgba(47, 46, 65, 0.5);
      
      
      /* Inside auto layout */
      
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-left: auto;
      margin-right: auto;
      width: 303px;
      padding-bottom: 24px
    }

    .login-footer{
      /* Body small | Oxygen */

font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

text-align: center;
letter-spacing: 0.25px;

/* Soft black | 50 */

color: rgba(47, 46, 65, 0.5);


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin-left: auto;
margin-right: auto;
width: 303px;
/* position: absolute;
bottom: 42px;
left: calc(50vw - 151.5px) */
padding-bottom: 16px
    }

    .login-footer-link{
      text-decoration: none;
      color: rgba(19, 76, 114, 1)
    }
  }

  .pxy-8{
    padding-top: 8px;
    padding-bottom: 8px;
  }





  