body{
    /* font-family: 'Oxygen'; */
    font-family: 'Poppins';
}

.main-body-wrap{
    width: 100%;
    /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%);
background: linear-gradient(180deg, #1D0EC9 0%, #D047CB 86.98%);
transform: rotate(40.19deg); */
/* background-image: url('../../assets/outhire/home-bg.svg'); */
background: linear-gradient(188.9deg, #3817C9 -4.18%, #D047CB 83.29%);
background-repeat: no-repeat;
background-size:cover;
padding-bottom: 75px;
overflow-x: hidden;
/* position: absolute;
top: -480px; */
transition: background 1s ease;
}

.max-width::-webkit-scrollbar{
    width: 5px;
}

html{
    width: 100vw;
    overflow-x: hidden;
}

.max-width::-webkit-scrollbar-thumb{
  background: rgba(47, 46, 65, 0.5);
    border-radius: 20px;
}

.bg-img{
    position: absolute;
width: 7035.33px;
height: auto;
left: 453.53px;
top: -3596.48px;
/* Gradient */
background: linear-gradient(188.9deg, #3817C9 -4.18%, #D047CB 83.29%);
transform: rotate(40.19deg);
}

.first-wrap{
    width: 100%;
    /* height: calc(100vh - 120px); */
    height: 50vw;
    position: relative;
    display: flex;
    flex-direction: row;
    /* margin-top: 600px */
}

.home-img{
    position: absolute;
    top: 5px;
    /* right: 200px; */
    /* width: 48%; */
    width: 53%;
    max-width: 950px;
    right: 12%;
    z-index: 3
}

.home-img1{
    margin-top: 20px;
    transform: none!important;
    max-width: none!important;
    position: unset!important;
    top: 5px;
    width: 600px;
    height: 802px;
    z-index: 3
}

.first-text-wrap{
    text-align: left;
    /* margin-left: 10%; */
    /* align-self: center; */
    /* margin-left: 6%; */
    margin-top: 7%;
    flex-wrap: nowrap;
    z-index: 3;
    width: 471px
}

.home-btn-wrap{
    flex-wrap: nowrap;
}

.mt-36{
    margin-top: 36px
}

.home-card-right-img-wrap{
    position: absolute;
    right: 51px;
    top: 180px;
    /* background-image: url('./../../assets/outhire/portal-bg.svg'); */
    background-size:cover
}

.home-title{
font-style: normal;
font-family: 'Poppins';
font-weight: 500;
font-size: 48px;
line-height: 72px;
max-width: 436px;

/* Soft Black */

color: #2F2E41;
}

.home-body-txt{
    font-style: normal;
    font-family: 'Poppins';
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
max-width: 471px;
margin-top: 40px;
margin-bottom: 40px;
}

.btn-home{
    width: 200px;
    border-radius: 8px;
    padding: 16px 0!important;
    font-size: 16px;
    font-weight: 700;
}

.home-img{
    right:108px!important
}

.btn-save, .btn-cancel, .btn-home{
    font-family: 'Oxygen';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    /* letter-spacing: 0.01em; */
    letter-spacing: 0.5px!important;
}

.w-img{
    width: 100%;
}

/* .btn-home:hover{
    background: #2F2E41;
    opacity: 0.1;
    z-index: -1;
}

.btn-cancel:hover{
    background: #2F2E41;
    opacity: 0.2;
    z-index: -1;
} */

.row1 {
    display: flex;
    flex-direction: row;
}

.main-first-wrap{
    position: relative;
    min-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content
}

.home-card-wrap{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    width: 998px;
    /* margin-top: 10vw; */
    padding: 48px 0
}

.home-card-wrap-forgetpw{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    width: 446px;
    padding: 48px 0;
    border: 6px solid rgba(56, 23, 201, 0.1);
border-radius: 12px;
}

.mb-140{
    margin-bottom: 240px
}

.w-no{
    width: 100%;
    max-width: none;
}

.row-center{
    justify-content: center;
}

.text-left{
    text-align: left;
}

.icon-text-wrap{
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-self: center;
    margin-left: 27px;
    /* justify-content: center; */
}

.home-icon{
    width: 40px;
    height: 40px;
    align-self: center;
    margin-right: 12px
}

.w-400{
    width: 400px
}

.weight-700{
    font-weight: 700;
}

.icon-txt{
    align-self: center;
    font-family: 'Oxygen';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
/* identical to box height, or 120% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;

}

.home-card-txt{
    max-width: 430px;
    margin-bottom: 50px
}

.home-card-btn-wrap{
    text-align: center;
}

.home-card-group{
    margin-left: auto;
    margin-right: 0;
    margin-top: 60px;
    width: 50%
}


.home-card-txt-wrap{
    /* width: 60%; */
    height:fit-content;
    align-self: center;
}

.home-group-img{
    width: 70%
}

.mb-100{
    margin-bottom: 100px;
}

.left{
    margin-right: auto;
    margin-left: 0
}

.text-right{
    text-align: right;
}

.home-sub-title{
    font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 43px;
/* identical to box height */

letter-spacing: 1.5px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 76px;
font-family: 'Poppins'!important;
}

.mt-47{
    margin-top: 47px;
    margin-bottom: 19px
}

.first-wrap{
    min-height: 55vw
}

@media(min-width: 2560px){
    .main-first-wrap{
        margin-top: 3vw
    }

    .top-section{
        padding-bottom: 8vw;
        margin-top: 10vw
    }

    .first-wrap{
        min-height:auto
    }

    .white-poligon{
        top: -15vw!important;
        width: 105vw!important;
        left: -2vw!important;
        transform: rotate(7deg)!important;
    }

    .first-text-wrap{
        /* transform: translateY(-20px); */
        margin-top: 7vw;
    }

    .home-img{
        width: 35vw;

    }

    .mac-img{
        width: 40vw;
        margin-top: 0px!important
    }
/* 
    .first-wrap{
        height: calc(100vh - 120px)
    } */
}

.line-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    font-size: 20px;
    margin-top: 40px
}

.go-icon{
    margin-left: 8px;
    cursor: pointer;
}

.w-fit{
    width: fit-content
}

.footer-txt{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41;
    
}

.ml-0{
    margin-left: 0!important
}

.footer2-txt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: 0.5px;

    /* Soft Black */

    color: #2F2E41;
    margin-bottom: 21px;
}

.footer{
    /* padding-top: 72px; */
    padding-bottom: 37px;
    text-align: left;
}

.footer-txt-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-logo{
    width: 174px;
    margin-bottom: 53px;
    padding-left: 1px;
    padding-top: 3px;
}

.footer-list-wrap{
    display: flex;
    flex-direction: row;
    margin-left: 133px;
}

.footer-list{
    margin-left: 60px
}

.footer-txt{
    margin-bottom: 16px;
}

.footer-link:hover{
    text-decoration: underline;
    cursor: pointer;
}

.footer-link{
    font-family: 'Oxygen';
    text-decoration: none!important;
}

.footer-icon-wrap{
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
}

.footer-icon{
    margin-left: 20px;
    cursor: pointer;
}

.w-100{
    width: fit-content;
    max-width: none;
}

.nav-wrap, .home-logo-wrap{
    z-index: 1000;
    background-color: #fff;
}

.mb-75{
    margin-bottom: 75px
}

.icon-text-container{
    text-align: left;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .first-wrap{
    position: relative;
  }

  .diagonal-box {
	 /* Absolute position */
     left: 0px;
     position: absolute;
     top: -52.5%;
 
     /* Take full size */
     height: 103%;
     width: 100%;
 
     /* Create diagonal sides */
     transform: skewY(-24.6deg);
 
     /* Background color */
     background-color: #fff;
 
     /* Displayed under the main content */
     z-index: 1;
}

.navbar{
    height: 120px!important
}

.btn-cancel{
    color: #2F2E41;
}

.btn-home{
    font-family: 'Oxygen'!important;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
}

.nav-list-txt{
    font-family: 'Oxygen', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    letter-spacing: 0.5px; 
}

.w-714{
    max-width: 714px;
    margin-left: auto;
    margin-right: auto;
}

/* .main-bottom-wrap{
    background-image: url('../../assets/outhire/over-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 70px;
} */

.mt-80{
    margin-top: 80px
}

.w-home-card-txt{
    max-width: 536px;
}

.w-home-card-600{
    max-width: 580px;
}

.ml-48{
    margin-left: 48px
}

.w-icon-txt{
    max-width: 553px;
}

.footer-content{
    max-width: 1355px;
    width: 1355px;
    display: flex;
    flex-direction: column;
}

.home-img{
    height: auto
}

@media(min-width: 1500px) and (max-width: 2559px){
    .white-poligon{
        top: -27%!important;
        width: 130%!important;
        right: 25px;
        transform: rotate(4deg)
    }

    .home-img{
        position: absolute;
        top: 5px;
        /* right: 200px; */
        /* width: 48%; */
        width: 53%;
        max-width: 900px;
    }

    .main-first-wrap{
        margin-top: 4%
    }
}

@media(min-width: 1441px) and (max-width: 1500px){
    .white-poligon{
        top: -24%!important;
        width: 130%!important;
        right: 25px;
        transform: rotate(5deg)
    }
}

@keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

.animation-txt-wrap{
    animation: 1s ease-out 0s 1 slideInFromBottom;
}

.first-wrap{
    overflow-y: hidden;
    overflow-x: hidden;
}

.inner-center-btn-wrap{
    text-align: center;
}

.map-img{
    width: 64.5vw;
    max-width: 930px
}

.swiper-wrapper {
    transition-timing-function: linear;
  }

  .swiper-slide{
    align-self: center;
  }

  .bottom-sec{
    min-height: 800px
  }

@media(min-width: 1281px) and (max-width: 1440px){
    .white-poligon{
        top: -11.5vw!important;
        width: 130%!important;
        right: 5px;
        transform: rotate(3.5deg)
    }

    .full-w-card{
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .mac-img{
        top: 5%;
        right: 10%;
        width: 55.3vw;
        max-width: 867px!important;
      
        margin-right: 60px
    }

    .employer-main{
        top: 5%;
        right: 10%;
        width: 52.3vw;
        max-width: 867px!important;
        margin-right: 90px
    }

    .candidate-main{
        top: 5%;
        right: 10%;
        width: 45vw;
        max-width: 867px!important;
        margin-right: 170px
    }

    .modal-main{
        max-height: 98vh;
    }

    .map-txt-wrap{
        transform: translateX(-60px)!important;
    }

    .first-text-wrap{
        margin-top: 7%;
    }

    .map-img{
        margin-left: 34px!important
    }
    }

/* @media(max-width: 1280px){
    .types-txt{
        text-align: center!important;
        margin-bottom: 16px;
    }
    .role-card{
        box-shadow: none!important;
        border-radius: 0!important;
        padding: 0!important;
        margin-top: 0!important;
    }
    .role-img{
        display: none;
    }
    .mo-column{
        flex-direction: column;
    }
    .role-card{
        width: 480px!important;
    }
    .mo-ml-0{
        margin-left: 0!important;
    }

    .home-fixed-card{
        width: 100%!important;
        margin-left: 2%!important;
    }

    .right-txt-wrap{
        margin-left: 10%!important;
    }

    .full-w-card{
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .blue-wrap{
        width: 80%!important;

    }
    .first-wrap{
        height: 61vw
    }

    .white-poligon{
        top: -21.5vw!important;
        width: 130%!important;
        left: -3px;
        transform: rotate(1deg)
    }

    .main-first-wrap{
        margin-left: 3vw
    }

    .home-img{
        right: 48px;
        top: 24px
    }

    .w-icon-txt{
        width: 350px
    }

    .icon-text-wrap{
        margin-left: 0px
    }

    .icon-text-container{
        margin-left: 16px
    }

    .home-card-right-img-wrap{
        top: 220px;
        right: 53px
    }
    .home-group-right-img{
        width: 250px;
    }

    .main-bottom-wrap{
        background-size: 1050px;
    }
} */

.navbar{
    width: 100vw
}

.first-wrap{
    height: fit-content!important;
    position: relative;
}

.main-bottom-wrap{
    transform: none!important;
}

.home-img{
    transform: none!important;
    max-width: none!important;
    position: unset!important;
}

.main-first-wrap{
    display: flex;
    flex-direction: row;
    margin-bottom: 150px;
}

@media(min-width: 2560px){
    .main-bottom-wrap{
        /* transform: translateY(-20vh)!important; */
        margin-top: 0px;
        
    }
    .main-body-wrap{
        height: fit-content
    }

    .mac-img .w-img{
        width: 40vw!important
    }
}

@media(min-width: 1280px){
    .main-bottom-wrap{
        transform: translateY(-140px);
        
    }
}

.white-poligon{
    object-fit: cover;
}

.main-body-wrap{
    position: relative;
}

.main-first-wrap{
    min-width: auto!important;
}

.home-img{
    margin-top: 20px
}

.full-w-card{
    background: #fff;
    padding: 102px;
    margin-bottom: 75px;
}

.side-txt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 51px;
/* identical to box height */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
margin-top: 64px;
}

.right-txt-wrap{
    width: 533px;
    margin-left: 290px;
    text-align: left;
}

.right-side-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 40px;
/* or 200% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 60px;
}

.s-center{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.w-full{
    width: auto
}

@media(min-width: 1920px) and (max-width: 2559px){
    .main-bottom-wrap{
        transform: translateY(-260px);
        
    }
    
    .top-section{
        padding-bottom: 8vw
    }

    .candidate-main{
        height: 802px;
    }

    .first-wrap{
        min-height: 50vw
    }

    .white-poligon{
        top: -13vw!important;
        width: 105%!important;
        left:auto!important;
        right: 0!important;
        transform: rotate(5deg);
    }

    .main-first-wrap{
        height: auto;
        min-width: 1500px;
        margin-top: 20px
    }

    .first-text-wrap{
        margin-top: 8%!important;
    }

    .inner-center-btn-wrap{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .home-img{
       width: 53vw!important;
       max-width: 800px!important
    }

    .first-text-wrap{
        margin-top: 10%
    }

}

@media(min-width: 1441px) and (max-width: 1919px){
    .main-bottom-wrap{
        transform: translateY(-260px);
        
    }
    
    .top-section{
        padding-bottom: 8vw
    }

    .candidate-main{
        height: 45vw;
    }

    .first-wrap{
        min-height: 50vw
    }

    .white-poligon{
        top: -13vw!important;
        width: 105%!important;
        left:auto!important;
        right: 0!important;
        transform: rotate(5deg);
    }

    .main-first-wrap{
        height: auto;
        min-width: 1500px;
        margin-top: 20px
    }

    .first-text-wrap{
        margin-top: 8%!important;
    }

    .inner-center-btn-wrap{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .home-img{
       /* width: 53vw!important; */
       max-width: 800px!important
    }

    .first-text-wrap{
        margin-top: 10%
    }

}

.btn-save:hover{
    /* background: rgba(54,28,174); */
    background: #19297c;
}

.btn-cancel:hover{
    background: rgba(234,234,236);
}

.flip-card{
    background-color: #fff;
    width: 300px;
    height: 230px;
    border-radius: 20px;
    display: flex;
    justify-content: center;

}

.home-fixed-card{
    width: 998px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 75px;
}

.xy-center{
    align-self: center;
}

.space-between{
    justify-content: space-between;
}

.role-card{
    width: 485px;

/* White */

background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 12px;
padding: 25px 0px
}

.ml-47{
    margin-left: 47px
}

.bullet-dot{
    width: 8px;
    height: 8px;
    align-self: center;
    margin-right: 10px;
}

.roles-txt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
}

.mr-60{
    margin-right: 60px
}

.ml-40{
    margin-left: 40px
}
.types-txt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 51px;
/* identical to box height */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 16px;
text-align: left;
}

.max-roles-wrap{
    width: 90%;
    max-width: 1328px;
    margin-left: auto;
    margin-right: auto;
}

.half-bg{
    background-image: url('../../assets/outhire/home-half-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.blue-wrap{
    width: 1066px;
    /* Deep Purple */
    
    background: #3817C9;
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px 72px;
    justify-content: space-between;
}

.blue-title{
/* Heading 1 */

font-family: 'Jost';
font-style: normal;
font-weight: 600;
font-size: 64px;
line-height: 125%;
/* or 80px */

text-align: center;
justify-content: center;

/* White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.blue-body-txt{
    font-family: 'Jost';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 150%;
/* or 33px */

text-align: center;

/* White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
}

.ptb-28{
    padding-top: 28px;
    padding-bottom: 28px;
}

.ptb-48{
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
}

.w-1360{
    width: 1360px;
    margin-left: auto;
    margin-right: auto;
}

.flip-txt{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    text-align: center;
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41;
    align-self: center; 
    width: 255px
}

.mo-nav-bar{
    display: none
}
.mo-show{
    display: none;
}

.mo-empower-img-wrap{
    display: none;
}

.mo-slider{
    display: none;
}

.mo-wrap{
    display: none;
}

.mo-copy{
    display: none;
}

@media(max-width: 1335px){
    .footer-content{
        width: 100vw;
        min-width:auto
    }
}
@media (max-width: 480px){
    .web-nav{
        display: none
    }

   

    .footer2-txt, .footer-txt{
        font-size: 14px;
        margin-bottom: 8px;
    }

    .mo-demo .nav-list-txt:hover{
        border-bottom: 1px solid #3817C9
    }

    .mo-nav-bar .bm-menu-wrap{
        width: 100vw!important;
        left: 0px
    }

    /* .mo-nav-bar .bm-overlay{
        display: none;
    } */

    .footer-logo{
        margin-bottom: 19px;
    }

    .web-copy{
        display: none;
    }

    .mo-copy{
        display: block;
        margin-left: 16px;
        margin-top: 24px;
    }

    .w-img{
        width: 100%;
        image-rendering: auto;
        image-rendering: crisp-edges;
        image-rendering: pixelated;
    }

    .mo-wrap{
        display: block;
    }

    .value-icons-wrap {
        width: auto!important;
    }

    .row1{
        justify-content: center!important;
    }

    .blue-wrap{
        width: calc(100vw - 168px);
        padding: 32px 52px;
    }

    .blue-title{
        font-weight: 600;
        font-size: 50px!important;
        line-height: 125%;
        /* or 80px */

        text-align: center;
    }

    .blue-title .animated-container div{
        font-size: 50px!important
    }

    .blue-body-txt{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        /* or 140% */

        text-align: center;
        letter-spacing: 0.15px;
    }

    .mo-pb-0{
        padding-bottom: 0px!important;
    }

    .web-slider{
        display: none;
    }

    .swiper-slide{
        height: fit-content
    }

    .mo-slider{
        display: block;
    }

    .mo-empower-img-wrap{
        display: block;
    }

    .mo-employer-img{
        width: 55%;
    }

    .main-body-wrap{
        width: 100vw
    }

    .max-width{
        width: 100vw;
        padding-top: 62px;
        height: calc(100vh - 62px)
    }

    .mo-nav-bar{
        display: flex;
        padding-top: 16px;
        padding-left: 16px;
        background-color: #fff;
        position: fixed;
        width: calc(100vw - 16px);
        z-index: 3000
    }

    .mo-logo{
        height: 46px;
    }

    .main-first-wrap{
        flex-direction: column;
        margin-left: 0px
    }

    .first-wrap{
        height: 469.5px!important
    }

    .mo-home-ham {
        width: 20px!important;
        height: 17px!important;
        align-self: center;
        top: 26px!important;
        right: 19px!important;
        left:auto!important
    }

    .web-home-txt{
        display: none;
    }

    .home-title{
        font-family: 'Poppins';
        font-style: normal;
        font-size: 34px;
        font-weight: 600;
        line-height: 51px;
        text-align: center;
        letter-spacing: 0.25px;
        width: 100%;
        max-width: none;

        /* Soft Black */
    }

    .white-poligon{
        width: 800px!important;
        top: -80px!important;
        left: -50px;
        transform: rotate(-3deg);
    }

    .home-img{
        width: 300px;
        margin-left: calc(50vw - 150px)
    }

    .home-img1{
        width: 300px;
        margin-left: calc(50vw - 150px)
    }

    .home-img2{
        width: 300px;
        margin-left: calc(50vw - 150px)
    }

    .home-img3{
        width: 300px;
        margin-left: calc(50vw - 150px)
    }

    .first-text-wrap{
        margin-top: 19px
    }

    .mo-show{
        display: flex;
    }

    .full-w-card{
        padding: 24px 13px 24px 13px;
        margin-bottom: 50px;
    }

    .home-body-txt{
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: center;
        letter-spacing: 0.5px;
        max-width: none;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 16px;
    }

    .mo-show .home-btn-wrap{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }


    .mo-show .home-btn-wrap .btn-save{
        margin-bottom: 16px;
    }

    .mo-show .home-btn-wrap .btn-save, .mo-show .home-btn-wrap .btn-cancel{
        margin-right: 0px;
        font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px!important;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.5px;
align-self: center;

/* White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
        height: 54px!important
    }

    .mo-column{
        flex-direction: column;
    }

    .side-txt{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
margin-top: 0px
    }

    .right-txt-wrap{
        margin-left: 0;
        text-align: center;
        width: 100%
    }

    .right-side-txt{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
margin-top: 16px;
margin-bottom: 16px;
    }

    .main-bottom-wrap .btn-save, .main-bottom-wrap .btn-cancel{
        margin-right: 0px;
        font-family: 'Oxygen';
        font-style: normal;
        font-weight: 700;
        font-size: 16px!important;
        line-height: 24px;
        /* identical to box height, or 150% */
        
        text-align: center;
        letter-spacing: 0.5px;
        
        /* White */
        
        color: #FFFFFF;
        
        
        /* Inside auto layout */
        
        flex: none;
        order: 0;
        flex-grow: 0;
        height: 54px!important
    }

    .full-w-card .home-title{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 0;
    }

    .home-fixed-card{
        margin-bottom: 50px;
    }

    .home-fixed-card .mo-column{
        justify-content: center;
    }

    .mb-75{
        margin-bottom: 50px;
    }

    .flip-card{
        width: 280px;
        height: 190px;
        margin-left: calc(50vw - 140px);
    }

    .mo-mb-16{
        margin-bottom: 16px;
    }

    .home-card-wrap{
        width: 100vw;
        box-shadow:none;
    }

    .home-card-right-img-wrap{
        display: none;
    }

    .mo-ml-0{
        margin-left: 0;
        justify-content: center;
    }

    .mo-home-card-txt{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
margin-right: 0px;
width: 310px;
/* margin-left: calc(50vw - 150px) */
    }

    .home-card-wrap .home-title{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 24px;
    }

    .home-icon{
        width: 27px;
        height: 27px
    }

    .icon-txt{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
width: 265px
    }

    .icon-text-container{
        margin-left: auto;
    }

    .types-txt{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */
        
        letter-spacing: 0.15px;
        
        /* Soft Black */
        
        color: #2F2E41;
        text-align: center;  
    }

    .role-img{
        display: none;
    }

    .role-card{
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        margin-top: 0;
    }

    .half-bg{
        background-image: none;
    }

    .role-card .bullet-dot{
        width: 4px;
        height: 4px;
        align-self: center;
    }

    .special-dot{
        align-self: flex-start!important;
        margin-top: 10px;
    }

    .roles-txt{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
max-width: 280px;
text-align: left;
    }

    .web-wrap{
        display: none;
    }

    .home-card-wrap{
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .w-714{
        width: 245px
    }

    .line-row{
        margin-top: 24px
    }

    .connect-link{
        width: fit-content
    }

    .footer-content{
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .footer-txt-wrap{
        flex-direction: column;
    }

    .footer-list{
        margin-left: 0;
    }

    .footer-icon-wrap{
        margin-left: 0px;
    }

    .footer-list-wrap{
        margin-left: 16px;
        flex-direction: column;
    }



}