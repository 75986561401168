.top-left{
    width: calc(40% - 62px);
}

.card-container{
    /* background-image: url('../../assets/outhire/job_wrap.png'); */
    /* background-color: #3817C9; */
    background-color: #DB48F3;
    border-radius: 8px ;
    width: 100%;
    padding-top: 8px;
    background-size:cover;
    background-repeat: no-repeat;
}

.mt-16{
    margin-top: 16px!important
}

.mt-0{
    margin-top: 0px!important
}

.ml-64{
    margin-left: 64px
}

.dot-list{
    width: auto;
    height: auto;
    align-self: flex-start;
    margin-left: 0;
    margin-right: 8px;  
    margin-top: 6px
}

.p-input{
    width: 95%;
    background-color: rgba(209, 79, 255, 0.05);
    padding: 10px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(209, 79, 255, 0.5);
    outline: none
}

.job-top{
    margin-left: 26px
}

.f-card{
    margin-left: 10px;
}

.mb-top-btn{
    margin-bottom: 20px
}

.top-btn{
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-top: 16px
}

.job-card{
    width: 360px;
    height: fit-content;
    
    /* White */
    
    background: #FFFFFF;
    /* Soft black | 50 */
    
    border: 0.25px solid rgba(47, 46, 65, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px; 
    margin-right: 40px;
    margin-bottom: 40px;
}

.jb-avatar{
    width: 40px;
    height: 40px;
    background-color: #3817C9;
    border-radius: 100%;
}

.jb-card-head{
    padding: 16px
}

.jb-title-wrap{
    margin-left: 16px;
    height: 68px;
}

.jb-title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41; 
}

.jb-sub-title{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
}

.jb-card-content{
    padding: 16px
}

.jb-description{
  /* margin-top: 32px  ; */
  margin-top: 12px;
  font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
}

.jb-card-btn{
    margin-left: auto;
    margin-right: 0;
    margin-top: 32px;
    width: fit-content
}

.jb-cards{
    margin-left: 40px;
    width: 80%
}

.wrap{
    flex-wrap: wrap;
}

.elipse{

}

.load-wrap{
    width: 100%;
    text-align: center;
    padding-top: 20%
}

.jb-avatar1{
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
}

.jb-avatar-img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    align-self: center;
   
}

.jb-card-image{
    width: 100%;
    height: 208px;
    background-repeat: no-repeat;
    background-color: #f8f9fb;
}

.elipis-txt{
    text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.elipis1-txt{
    text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.top-status-card{
    /* White */

background: #FFFFFF;
border-radius: 8px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
padding: 16px;
margin-left: 42px
}

.status-title{
    font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* or 150% */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 18px
}

.job-card-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.status-icon{
    width: 50px;
    margin-left: auto;
    margin-right: auto
}

.a-status-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 13px;
/* identical to box height */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
width: fit-content;
margin-top: 10px;
max-width: 71px;
height: 26px
}

.status-iten-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.status-row-wrap{
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.a-status-step{
    width: 21px;
    height: 2px;
    
    /* Soft Black */
    
    background-color: #2F2E41;
    margin-top: 24px;
    margin-left: 6px;
    margin-right: 6px
}

.status-icon-wrap{
    text-align: center;
}

.status-txt-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bottom-btns-wrap{
    text-align: right;
    padding-bottom: 60px;
}

.btn-long{
    width: 162px!important;
    margin-right: 0
}

.n-px{
    padding: 8px 0px!important;
    width: 180px!important
}

.job-link-url{
    /* Body large | Poppins */

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 24px;
max-width: 410px;
}

.status-center{
    margin-left: auto;
    margin-right: auto;
}

.detail-card{
    border: 6px solid rgba(56, 23, 201, 0.1);
    border-radius: 8px; 
}

.detail-icon{
    margin-right: 12px;
    /* height: 20.36px;
    width: 20px; */
    max-height: 21px;
    width: auto;
}

.cm-row{
    margin-top: 24px;
    display: flex;
    flex-direction: row;
}

.cm-img-wrap{
    background-color: #f8f9fb;
    width: 200px;
    height: 120px;
    margin-right: 32px;
    margin-left: 16px
}

.mt-0{
    margin-top: 0px
}

.info-group{
    min-width: 100px;
}

.info-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
}

.cm-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.time-line{
   display: -webkit-box;
   -webkit-line-clamp: 5; /* number of lines to show */
   -webkit-box-orient: vertical;
   overflow: hidden;
   max-height: 120px;
   margin-bottom: 8px;
}

.detail-see-more{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    
    letter-spacing: 0.25px;
    
    /* Soft Black */
    
    color: #2F2E41;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 16px
}

.n-modal-group{
    max-width: 582px;
}

.copied-txt{
    font-size: 14px;
    margin-top: 8px;
    color: rgba(47, 46, 65, 0.7)
}

.mo-card{
    display: none;
}

.mo-top-btn{
    display: none;
}

.mo-status{
    display: none;
}

@media(max-width: 480px){
    .jb-cards{
        margin-top: 48px!important;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 22px;
        padding-right: 22px;
        width: 100vw
    }

    .mb-top-btn{
        margin-bottom: 0px!important;
    }

    .mo-bottom-ex{
        margin-top: 70px!important
    }

    .job-card{
        width: calc(100% - 44px);
        margin-right: 0;
        margin-bottom: 16px;
    }

    .main-canvas{
        overflow-x: hidden;
    }

    .card-image{
        width: 100%
    }

    .top-btn{
        display: none;
    }

    .job-top{
        margin-left: 0px
    }

    .mo-bottom{
        margin-top: 50px!important;
    }

    .mo-card{
        display: block;
        width: calc(100% - 50px);
        margin-left: 22px;
        padding-top:10px;
        margin-bottom: 16px
    }

    .f-card{
        margin-bottom: 0px;
    }

    .detail-card{
        margin-bottom: 16px;
    }

    .mo-top-btn{
        display: block;
        margin-left: auto;
        margin-right: auto
    }

    .mb-top-btn{
        margin-bottom: 16px;
        z-index: 1
    }

    .top-sec{
        z-index: 10;
    }

    .n-modal-txt{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

text-align: center;
letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
width: calc(100% - 78px)
    }

    .top-status-card{
        display: none;
    }

    .mo-status{
        display: block;
        margin-bottom: 16px;
        margin-left: auto;
        margin-right: auto;
    }

    .load-wrap{
       padding-right: 40px
    }

    .detail-card{
        width: calc(100% - 38px)
    }
}