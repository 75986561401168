.body-title{
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */
color: #2F2E41;
}

.body-txt{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
}

.mt-32{
    margin-top: 32px
}

.v-center{
    align-self: center;
}

.btn-10{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 12px!important;
    line-height: 13px;
    /* identical to box height */
    
    letter-spacing: 0.5px;
    
    /* White */
    
    color: #FFFFFF;
}

.mt-10{
    margin-top: 10px
}

.bottom-container{
    margin-left: 36px
}

.refer-input{
    border: 1px solid rgba(47, 46, 65, 0.2);
    border-radius: 5px;
    outline: none;
    padding:8px;
    width: 360px
}

.refer-input::placeholder{
    color: rgba(47, 46, 65, 0.3)
}

.ml-16{
    margin-left: 16px
}

.ml-24{
    margin-left: 24px
}

.w-35{
    width: 35%
}

.w-10{
    width: 10%
}

.mt-8{
    margin-top: 8px
}

.o-modal-refer-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.o-modal-terms-link{
    font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.75px;
text-decoration-line: underline;

/* Soft Black */

color: #2F2E41;
margin-top: 13px
}

.refer-email-wrap{
    width: 600px;
    height: auto;
    left: 0px;
    top: 0px;
    
    /* White */
    
    background: #FFFFFF;
    /* Soft black | 50 */
    
    border: 1px solid rgba(47, 46, 65, 0.5);
    text-align: left; 
    padding: 10px;
    padding-bottom: 0px;
    margin-bottom: 16px
}

.refer-email-label{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.75px;

/* Soft Black */

color: #2F2E41;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.refer-emails{
    outline: none;
 
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin-top: 8px;
width: 100%;
height: 30px!important;
border: 0;
}

.refer-message{
    height: auto;
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
width: 100%;
border: 0
}

.refer-mesage-label{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 13px;
/* identical to box height */

letter-spacing: 0.5px;

/* Soft black | 50 */

color: rgba(47, 46, 65, 0.5);


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.btn-refer-more{
    width: 193px;
    margin-top: 16px
}

.mo-refer{
    display: none;
}

@media(max-width: 480px){
    .bottom-container{
        margin-left: 22px;
        margin-right: 22px;
        padding-top: 70px
    }

    .mo-refer{
        display: block;
        text-align: center;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }

    .btn-send{
        margin-right: 0;
    }

    .mo-ml-0{
        margin-left: 0px;
        margin-top: 16px
    }

    .mo-none{
        display: none;
    }

    .m-w-35{
        width: 80%
    }

    .refer-email-wrap{
        width: calc(100% - 21px)
    }

    .refer-email-label, .refer-emails, .refer-message{
        font-size: 14px
    }
}