.last-dropped{
    background: linear-gradient(180deg, #1D0EC9 0%, #D047CB 86.98%);
/* White */

border: 1px solid #FFFFFF;
border-radius: 8px;
width: 130px;
height: fit-content;
padding-top: 8.5px;
padding-bottom: 8.5px;
margin-top: 22px;
margin-left: auto;
margin-right: auto;
font-weight: 700;
font-size: 14px;
line-height: 18px;
text-align: center;
letter-spacing: 0.75px;
font-family: 'Oxygen';
font-style: normal;
color: #fff;
}

.smaller-txt{
    font-weight: 700!important;
font-size: 10px!important;
line-height: 13px!important;
text-align: center;
letter-spacing: 0.5px!important;
padding-top: 4.5px!important;
padding-bottom: 4.5px!important;
word-wrap: break-word!important;
padding-left: 24px!important;
padding-right: 24px!important;
width: 82px!important;
}

.smaller-pd{
    font-weight: 700!important;
    font-size: 10px!important;
    line-height: 13px!important;
    text-align: center;
    letter-spacing: 0.5px!important;
    padding-top: 4.5px!important;
    padding-bottom: 4.5px!important;
    word-wrap: break-word!important;
    padding-left: 8px!important;
    padding-right: 8px!important;
    width: 114px!important;
}

.box-wrap{
    width: calc(528px + 6rem);
    margin-left: auto;
    margin-right: auto;
}

.dustbin-wrap{
    position: relative;
}

.dustbin-index{
    position: absolute;
    left: 130px;
    top: 30px
}

@media(max-width: 480px){
    .dustbin-index{
        position: absolute;
        left: 30px;
        top: 30px
    }
}