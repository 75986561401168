.candidate-bg{
    /* background-image: url('../../assets/outhire/candidates-bg.svg')!important; */
    background: linear-gradient(180.46deg, rgba(219, 72, 243, 0.8) 0.4%, rgba(219, 72, 243, 0) 124.2%);
}

.candidate-home{
    width: 45%;
    transform:translateY(-50px);
}

.margin-center{
    margin-left: auto;
    margin-right: auto;
    justify-self: center;
    width: fit-content;
}

.circle-txt-wrap{
    width: 556px
}

.circle-link{
    color: rgba(19, 76, 114, 1)!important;
    cursor: pointer;
    text-decoration: underline;
}

.circle-txt{
   height: 48px
}

.circle-txt1{
    text-align: justify!important;
    height: fit-content!important;
 }

.circle-txt, .circle-link{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Soft Black */

    color: #2F2E41;
}

.relative-container{
    position: relative;
}

.solid-prev{
    align-self: flex-start;
    /* margin-left: 15px; */
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: -30px
}

.flex{
    display: flex!important;
    flex-wrap: nowrap!important;
    width: 100vw
}

.solid-next{
    align-self: flex-start;
    /* margin-left: 15px; */
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: -30px
}

.candidate-line-row{
    justify-content: left;
    margin-top: 60px;
    font-size: 20px
}

.card-body-sm{
  /* Body | 18 */

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Soft black */

    color: #373F41;
    max-width: 191px;
    height: fit-content;
    margin-bottom: 0;
}

.w-813{
    width: 813px;
    margin-left: auto;
    margin-right: auto;
}

.candidate-first-text{
    width: 500px
}

.num-img{
    margin-bottom: 22px;
}

/* .candidate-bottom-bg{
    background-image: url('../../assets/outhire/over-candidate-bg.svg');
    background-position-y: 200px;
} */

.w-191{
    width: 100px;
    text-align: center;
    margin-left: auto;
    margin-right: auto
}

.pb-0{
    padding-bottom: 0px!important;
}

.mb-32{
    margin-bottom: 60px;
}

.w-445{
    width: 445px
}

.circle-img-wrap{
    overflow:clip;
    position: relative;
}

.img-btn1{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 295px;
    right: 850px;
    background: transparent;
    cursor: pointer;
    border: 0
}
.img-btn2{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 35px;
    right: 685px;
    background: transparent;
    cursor: pointer;
    border: 0
}
.img-btn3{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 35px;
    right: 250px;
    background: transparent;
    cursor: pointer;
    border: 0
}
.img-btn4{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 295px;
    right: 90px;
    background: transparent;
    cursor: pointer;
    border: 0
}

.home-card-wrap{
    overflow-x: hidden;
}

.circle-img{
    margin-left: 75px;
    margin-right: 81px
}

.move-left1{
    margin-left: -991px
}

.move-left2{
    margin-left: -1982px
}

.move-left3{
    margin-left: -2973px
}

.can-lines-wrap{
    display: none;
}

@media(max-width: 480px){
    .candidate-first{
        height: 550px!important
    }

    .circle-txt{
        height: fit-content
    }

    .f-nowrap{
        display: flex;
        flex-wrap: nowrap;
    }

    .circle-img{
        margin-left: calc(50vw - 100px);
        margin-right: calc(50vw - 100px);
    }

    /* .circle-img-wrap{
        margin-left: auto;
        margin-right: auto;
        width: 100vw;
    } */

    .flex{
        position: relative;
    }

    .flex .solid-prev{
        display: block!important;
        left: 24px;
        bottom: 50%
    }

    .solid-prev{
        display: none;
    }

    .flex .solid-next{
        display: block!important;
        right: 24px;
        bottom: 50%
    }

    .move-left1{
        margin-left: -100vw
    }
    
    .move-left2{
        margin-left: -200vw
    }
    
    .move-left3{
        margin-left: -300vw
    }

    .can-lines-wrap{
        display: block;
        width: 90%;
        margin-left: auto;
        margin-right: auto
    }

    .can-lines-img{
        margin-left: 15px
    }

    .mo-row{
        justify-content: flex-start;
    }

    .mo-open-role{
        margin-top: 16px;
        margin-bottom: 24px;
        width: fit-content;
       margin-left: auto;
       margin-right: auto;
    }

    .mo-open-role .icon-txt{
        font-family: 'Oxygen';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.75px;

        /* Soft Black */

        color: #2F2E41;
        width: 122px
    }

    .mo-sub-title{
        width: 96%
    }

    .mo-row{
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        width: 90%
    }

    .mo-row .num-img-wrap{
        width: 35px;
        height: 30px;
        margin-right: 16px;
    }

    .mo-row .num-img{
        width: 100%;
        height: 28px
    }

    .mo-row .card-body-sm{
        font-family: 'Oxygen';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        letter-spacing: 0.25px;

        /* Soft Black */
        width: 100%;
        text-align: left;
        max-width: none;
    }

    .mo-311{
        width: 311px;
        margin-left: auto;
        margin-right: auto;
    }

    .mo-2line{
        width: 202px;
        margin-left: auto;
        margin-right: auto
    }

    .circle-txt-wrap{
        width: 275px
    }

    .mo-frame{
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto
    }

    .mo-px-0{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }

    .solid-next{
        display: none;
    }
}