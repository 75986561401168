.sans-large{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 43px;
    letter-spacing: 0.25px;
}

.w-full4{
    width: 100%;
    z-index: 4;
}

.bottom-card{
    padding-bottom: 400px;
}

.about-first-wrap{
    z-index: 50
}

.jb-cards{
    text-align: left;
}

.btn-save, .btn-cancel{
    border-radius: 8px;
}

.w-718{
    width: 718px
}

.home-jb-cards{
    /* margin-left: calc( 4vw - 20px);
    margin-right: calc( 4vw - 20px);
    width: calc(92vw + 40px); */
    width:1210px;
    margin-left: auto;
    margin-right: auto;
    z-index: 4;
    margin-top: 200px!important;
    /* justify-content: center; */
}

.home-job-card{
    margin-left: 20px;
    margin-right: 20px;
}

.white-poligon{
    position: absolute;
    top: -11.5vw;
    width: 130%;
    right: 5px;
    transform: rotate(3.5deg);
}

.ex-first{
    position: unset;
}

.jb-description{
    height: 40px
}

.guest-avatar-wrap{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    width: 61px;
    height: 62px;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.guest-avatar{
    width: 20px;
    height: 30px;
    margin-left: 20px;
    margin-top: 10px
}

.guest-text{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.5px;

    /* Soft Black */

    color: #2F2E41;
    margin-top: 4px
}

.job-card{
    font-family: 'Oxygen';
font-style: normal;
}

.jb-title{
    /* Oxygen B | 16 */

    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.5px;

    /* Soft Black */

    color: #2F2E41;
}

@media (max-width:1280px){
    .top-left{
        width: 34vw
    }

    .card-wrap{
        width: 34vw
    }

    .side-menu {
        position: absolute;
        width: 240px;
        height: calc(100vh - 88px);
        /* background-image: url(../../assets/outhire/jb-bg.svg); */
        
    }

    .ex-first .ex-white{
        top: -10%!important
    }
}

@media(max-width: 1440px) and (min-width: 1281px){
    .ex-white{
        top: -5%!important
    }
}

@media(min-width: 1441px){
    .ex-white{
        top: -10%!important
    }
}

@media(max-width: 480px){
    .about-first-wrap{
        padding-left:0px!important;
        width: 100vw!important
    }

    .mo-card-container{
        height: fit-content!important
    }

    .mo-side-bar .menu-logo-wrap{
        position:unset!important;
        margin-top: 16px!important
    }

    .about-body-txt{
        width: 276px!important;
        margin-left: auto;
        margin-right: auto;
    }

    .first-text-wrap{
        width: 100vw!important
    }

    .mo-bottom-jb{
        margin-top: 50px!important
    }

    .home-jb-cards {
        margin-top: 48px!important;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 22px;
        padding-right: 22px;
        width: 100vw;
    }

    .home-job-card {
        margin-left: 0px;
        margin-right: 0px;
    }

    .ex-first{
        height: fit-content!important
    }

    .ex-first .ex-white {
        top: -50px!important;
    }
}

.card-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

