.p-3{
    padding-top: 16px;
    padding-bottom: 16px
}

.btn-sm-light{
    /* White */

background: #FFFFFF;
/* Deep Purple */

border: 1px solid #3817C9;
border-radius: 12px;
padding: 8px;
outline: none;
cursor: pointer;
}

.mr-10{
    margin-right: 10px
}