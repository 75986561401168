/**ICON BANNER**/
.banner-wrap{
    width: 70%;
    min-width: 976px;
    display: flex;
    flex-direction: row;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .banner-list-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .banner-txt{
    width: 160px;
    height: fit-content; /* 48px */
    white-space: pre;
    text-align: center;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 20px;
    color: var(--Primary_Blue, #0f1237);
  }

  .banner-icon{
    width: 100px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  /**SECTION TITLE**/

  .section-title{
    white-space: pre;
    font-size: 40px;
    font-weight: 700;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    color: var(--Primary_Blue, #0f1237);
    margin: 0 auto;
  }

  .title-sub-divider{
    width: 148px;
    height: 8px;
    background-color:  #f47160;
    overflow: visible;
    border-radius: 4px;
    border-width: 0;
    margin-top: 24px;
  }

  /**BLUE BANNER**/
  .blue-banner{
    width: 100%;
    background-color: #0f1237;
    margin-top: 89px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .blue-banner-body {
    width: 538px;
    height: 84px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 42px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    font-size: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  .blue-banner-txt {
    width: fit-content; /* 272px */
    height: fit-content; /* 21px */
    overflow: visible;
    white-space: pre;
    text-align: left;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 16px;
    line-height: 1.35;
    color: #e3eaf2;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
  }

  .talk-container{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    min-width: 976px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;
    flex-wrap: wrap;
  }

  // TALK TO SPECIALISTS SECTION
  .talk-card {
    width: 47.5%;
    min-width: 470px;
    flex-shrink: 0;
    height: fit-content;
    padding: 24px 0px;
    box-shadow: 10px 10px 40px 0px var(--GB_200, #e3eaf2);
    background-color: #ffffff;
    overflow: visible;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom: 24px;
  }

  .talk-img-wrap{
    width: 95px;
    height: 95px;
    margin-right: 35px;
    margin-left: 32px;
    margin-top: 10px;
  }

  .talk-title {
    width: 212px;
    height: 28px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 500;
    font-size: 24px;
    color: var(--Primary_Blue, #0f1237);
    margin-bottom: 8px;
  }

  .talk-txt {
    width: 269px;
    height: fit-content;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    line-height: 1.35;
    letter-spacing: 0.5px;
    font-size: 16px;
    color: #0f1237;
  }

  .talk-btn-wrap{
    display:flex;
    flex-direction: row;
  }

  .btn-talk{
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    width: 129px;
    margin-top: 14px;
  }
  
  .btn-no-border{
    border: 1px solid #fff;
    margin-left: 16px;
  }

  .btn-no-border:hover{
    color: #0f1237 !important;;
    background-color: #fff !important;
  }

  .item-center{
    margin-left: auto;
    margin-right: auto;
  }

  /**FAQ**/
  .faq-wrap{
    width: 100%;
    height: fit-content;
    background-color: #f8f9fb;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .faq-content{
    margin-top: 48px;
    width: 40%;
    min-width: 701px;
    margin-left: auto;
    margin-right: auto;
  }

  .faq-title{
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color: #607890;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  /**ACCORDION LIST**/
  .accordion-section{
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .accordion{
    background-color: #fff;
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 26px 32px;
    cursor: pointer;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    display: flex;
    flex-direction: row;
    outline: none;
  }

  .accordion:hover{
    border: 1px solid #f47160;
  }

  .accordion.active{
    border: 1px solid #f47160;
    background-color: #f47160;
    color: #fff;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .accordion-title{
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
  }

  .accordion-content{
    background-color: #fff;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .accordion-text{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    padding: 18px 32px;
  }

  .accordion-icon{
    width: 18px;
    float: right;
    margin-left: auto;
  }

  /**Contact**/
  .contact-wrap{
    width: 70%;
    min-width: 976px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f8f9fb;
    padding-top: 51px;
    height: 360px;
  }

  .height-contact{
    height: 103px;
    line-height: 1.35;
    margin-bottom: 28px;
  }

  .contact-card-wrap{
    display: flex;
    flex-direction: row;
    margin: auto 119px;
  }

  .contact-card{
    background-color: #fff;
    border-radius: 4px;
    padding: 43px 53px;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 71px;
  }

  .contact-card-big{
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    color: #0f1237;
    margin-bottom: 43px;
    width: 261px;
  }

  .contact-img{
    width: 97px;
    height: 71px;
    margin-bottom: 26px;
  }

  .contact-card-small{
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    color: #0f1237;
    margin-top: 8px;
  }

  /**Footer**/
.footer-container{
  width: 100%;
  background-color: #0f1237;
  height: auto;
  padding-bottom: 24px;
}

.footer-content{
  width: 80%;
  min-width: 976px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  padding-top: 41px;
  justify-content: space-between;
  // margin-bottom: 37px;
}

.mt-238{
  margin-top: 238px;
}

.footer-title{
  color: #8c9db1;
  margin: 0;
  text-align: left;
}

.footer-body{
  color: #fff;
  text-align: left;
}

.mt-80{
  margin-top: 80px;
}

.mt-40{
  margin-top: 40px
}

.media-icon-wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.media-icon{
  width: 24px;
  height: auto;
}

/******Modal******/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200
}


.a-modal-main {
  position:fixed;
  background: white;
  width: fit-content;
  height: auto;
  max-height: 960px;
  top:50%;
  left: 75%;
  transform: translate(-50%,-50%);
  transition: all 1s ease-in-out;
  border-radius: 0;
  max-width: 792px;
  z-index: 400
}

.modal-main {
  position:fixed;
  background: white;
  width: fit-content;
  height: auto;
  max-height: 960px;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 12px;
  z-index: 400
}

.a-modal-main.show1{
  left: 150%;
}

.a-modal-main.show2{
  left: 50%;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.weight-modal-close{
  margin-top: 18px;
  margin-right: 16px;
  margin-left: auto;
  float: right;
  outline: none;
  background-color:transparent;
  border: none;
  cursor: pointer;
}

.w-close-icon{
  width: 18px;
  height: 18px;
}

.copy-right-txt{
  width: 311px
}