.side-menu-ad{
    position: absolute;
width: 240px;
height: calc(100vh - 88px);
 /* background-image: url('../../assets/outhire/bg-admin.svg'); */
background: linear-gradient(180deg, #1D0EC9 0%, #D047CB 86.98%);
background-color: #fff;
border-radius: 0px;
background-repeat: no-repeat;
}

.menu-list1:hover, .active-menu1{
    /* background-color: rgba(209, 79, 255, 0.05); */
    /* background-color: rgba(243,213, 72, 0.15); */
    background-color: rgba(105, 80, 215);
}

.bg-white{
    background: #fff!important;
}

.menu-list1{
    display: flex;
    flex-direction: row;
    padding: 24px 40px 16px 40px;
    cursor: pointer;
    margin-bottom: 4px
}

.table-card{
    height: fit-content;
    width: calc(100% - 64px);
    background-color: #fff;
    margin-top: 24px;
    border-radius: 4px;
    padding: 24px 24px;
    min-height: calc(100vh - 188px);
}

.block-header{
    font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 32px
}

.block-tab{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.75px;

/* Soft Black */

color: #2F2E41;
display: flex;
flex-direction: row;
}

.appt-title-wrap{
    width: fit-content;
    padding: 4px 24px 8px 24px;
    cursor: pointer;
}

.ml-apptitle {
    margin-left: 24px
}

.block-divider{
    width: 100%;
    height: 1px;
    background-color: #e3eaf2;
}

.tab-active{
    border-bottom: 4px solid #3817c9!important;
}

.c-table{
    width: 100%;
}

.table-header{
    display: flex;
    flex-direction: row;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(47, 46, 65, 0.5);
}

.table-head{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
align-self: center;
}

.name-head{
    width: 10%;
}

.method-head{
    width: 12%;
}

.email-head{
    width: 25%
}

.action-head{
    width: 30%
}

.table-data-wrap{
    width: 100%;
}

.table-body{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(47, 46, 65, 0.5);
    padding: 9px 0px
}

.table-btn-wrap{
    display: flex;
    flex-direction: column;
    align-self: center;
}

.btn-action{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 14px;
gap: 13.66px;
cursor: pointer;

/* White */

background: #FFFFFF;
/* Deep Purple */

border: 1px solid #3817C9;
border-radius: 5px;
color: #3817C9;
height: fit-content;
font-size: 12px;
width: fit-content;
width: 120px;
font-weight: 600;
}

.btn-action:disabled{
    opacity: 0.4;
    cursor: auto;
}

.mb-8{
    margin-bottom: 8px
}

.action-head{
    display: flex;
    flex-direction: row;
}

.input-group{
    margin-left: 18px;
    align-self: center;
}

.tinput-label{
    font-weight: 500;
font-size: 10px;
line-height: 13px;
/* identical to box height */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.t-input{
    background: rgba(209, 79, 255, 0.05);
    outline: none;
    border-width: 0px;
    font-size: 14px;
    padding: 8px 8px;
    margin-right: 10px
}

.ml-12{
    margin-left: 12px
}

.t-icon{
    cursor: pointer;
} 

.n-modal-txt{
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
}
