.float-right{
    justify-content: end;
}

@media(max-width: 480px){
    .btn-account{
        width: 200px;
        margin-left: auto;
        margin-right: auto;

/* Soft Black */

color: #2F2E41;
    }

    .mo-wrap{
        flex-wrap: wrap;
    }

    .am-wrap{
        flex-wrap: wrap;
    }

    .mo-account-mt{
        margin-top: 12px;
    }


    .mo-w-100{
        width: 100%;
        font-size: 14px!important
    }
}

.error-txt{
    color: red;
    margin-top: 8px
}