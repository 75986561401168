//styles for Top NavBar

.navbar{
    width: 100%;
    height: 130px;
    min-height: 100px;
    background-color: #ffffff;
    overflow: visible;
    // box-shadow: 0px 4px 5px 0px #e3eaf2;
    justify-content: center;
    display: flex;
    z-index: 2;
    transition: all 0.7s ease-in;
    position: fixed;
}

.nav-wrap{
    width: 100%;
    // max-width: 1640px;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: row;
}

.home-logo-wrap{
    width: 174px;
    height: auto;
    // margin-left: 56px;
    margin-left: 3vw;
}

.scrolled{
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
}

.navigation{
    margin-left: auto;
    margin-right: 60px;
    height: fit-content;
    align-self: center;
    z-index: 100
}

.nav-list-wrap{
    list-style: none;
    display: flex;
    flex-direction: row;
    height:fit-content;
    margin-top: 0;
    margin-bottom: 0;
}

.nav-list-txt{
    width: auto; /* 146px */
    height: 24; /* 28px */
    font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 0.5px;

/* Soft Black */


    margin-right: 42px;
    cursor: pointer;
    border-bottom: solid 3px #fff
}

.nav-list-link{
    text-decoration: none;
    
}

.nav-list-txt:hover, .active-nav{
    // color: #f47160;
    border-bottom: solid 3px #3817c9;
}

.nav-btn-wrap{
    height:fit-content;
    width: fit-content;
    align-self: center;
    margin-left: auto;
    margin-right: 64px;
    float: right;
}

.btn-nav{
    width: 156px;
}

.nav-sign-btn{
    color: var(--Primary_Red, #f47160);
    border:0px;
    background-color: transparent;
    margin-right: 46px;
}

.nav-sign-btn:hover{
    color: #0f1237;
}

/**PORTAL SIDE NAV BAR**/
.side-nav{
    width: 313px;
    min-width: 313px;
    height: auto;
    background-color: #fff;
    padding: 152px 24px 0 24px;
    text-align: left;
    position: relative;
    min-height:700px;
}

.side-nav-dr{
    width: 240px;
    min-width: 240px;
    height: auto;
    background-color: #fff;
    padding: 152px 24px 0 24px;
    text-align: left;
}

.side-nav-wrap{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

.side-nav-group{
    display: flex;
    flex-direction: column;
    width: 313px;
    min-width: 313px;
}

.side-nav-dr .side-nav-group{
    width: 240px;
    min-width: 240px;
}

.side-nav-title{
    white-space: pre;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--GB_600, #607890);
    font-size: 14px;
    margin-left: 20px
}

.whole-canvas{
    background-color: #f8f9fb;
    width: 100%;
    height: 100vh;
}

.side-nav-list-wrap{
    width: auto;
    border-radius: 4px; 
    height: 40px;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    margin-top: 8px;
    cursor: pointer;
}

.side-nav-list-wrap:hover{
    background-color: #f8f9fb;
}

.side-list-active{
   background-color: #f8f9fb;
}

.side-list-active .side-nav-txt{
    color: #0499ff;
    font-weight: 500;;
}

.side-nav-txt{
    white-space: pre;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.2;
    font-size: 18px;
    color: var(--GB_600, #607890);
    align-self: center;
    margin-left: 16px;
}

/**PORTAL HEADER BAR**/
.portal-header{
    width: 100%;
    height: fit-content;
    box-shadow: 0px 1px 10px 0px rgba(32, 33, 36, 0.25);
    background-color: #ffffff;
    overflow: visible;
    padding: 16px 0 16px 0px;
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 50;
}

.portal-logo-wrap{
   width: 89px;
   height: 56px; 
   cursor: pointer;
   margin-left: 40px;
}

.portal-header-right{
    width: fit-content;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: row;
    float: right;
    margin-left: auto;
    margin-right: 20px;
}

.header-avatar-wrap{
    width: 48px;
    height: 48px;
    background-color: #f8f9fb;
    border-radius: 50%;
}

.header-icon-wrap{
    margin-right: 44px;
    width: 30px;
    height: fit-content;
}

.header-icon{
    width: 30px;
    height: auto;
}

.red-dot{
    width: 8px;
    height: 8px;
    //background-color: #f47160;
    background-color: #0499ff;
    border-radius: 50%;
    position: fixed;
    margin-top: -30px;
    margin-left: 12px;
}

.header-avatar{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
}

.header-name-wrap{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: fit-content;
    align-self: center;
}

.header-name-txt{
    white-space: pre;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    font-weight: 400;
}

.header-type-txt{
    white-space: pre;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--GB_600, #607890);
    margin-top: 3px;
}

.header-icon-wrap{
    height: fit-content;
    width: fit-content;
    align-self: center;
    margin-left: 44px;
    cursor: pointer;
}

.num-badge{
    width: 21px;
    height: 21px;
    background-color:  #0499ff;
    overflow: visible;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 0px;
    display:flex;
    justify-content: center;
    align-self: center;
}

.side-num{
    font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  align-self: center;
}

.btn-visit{
    width: 162px;
    line-height: 1.2;
    font-size: 20px;
    font-weight: 500;
}

.ml-50{
    margin-left: 50px;
}

.mr-16{
    margin-right: 16px;
}

.ml-auto{
    margin-left: auto;
}

.side-icon{
    align-self: center;
}

.side-nav-icon-wrap{
    display: flex;
}

.btn-book{
    font-size: 16px;
    font-weight: 500;
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
}

.notification-wrap{
    position:fixed;
    border-radius: 4px;
    box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--token-51d85200-76ab-4c28-8257-ded3b07416e3, #ffffff);
    right: 320px;
    top: 75px;
    max-height: 786px;
    overflow-y: auto;
    padding-bottom: 16px
}

.not-body{
    width: 480px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    padding: 12px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.not-body:hover{
    background-color: #e3eaf2;
}

.not-header{
    display: flex;
    flex-direction: row;
    width: 480px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 24px;
    margin-bottom: 12px;
}

.not-date{
    text-transform: uppercase;
    font-weight: 500;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 1.4;
}

.not-mark{
    margin-left: auto;
    margin-right: 0;
    color: #607890;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
}

.not-mark:hover{
    text-decoration: underline;
}

.not-bodytxt-wrap{
    text-align: left;
    width: 369px;
    margin-left: 12px;
    font-size: 16px;
}

.not-title{
    font-weight: 500;
}

.not-show{
    color: var(--Link, #1a7dff);
  letter-spacing: 0px;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px
}

.not-hint{
    margin-top: 16px;
    text-transform: uppercase;
    font-size: 12px;
    color: #607890;
    font-weight: 500;
}

.not-dot{
    width: 11px;
  height: 11px;
  background-color: var(--token-f5b42cc6-f60a-4580-b2d4-7b92d760d417, #1a7dff);
  overflow: visible;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 0
}

.account-wrap{
    width: 260px;
    position: fixed;
    box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--token-51d85200-76ab-4c28-8257-ded3b07416e3, #ffffff);
  overflow: visible;
  border-radius: 4px;
  top: 75px;
  right: 70px
}

.account-head{
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    padding-bottom: 20px;
    padding-left: 19px
}

.account-menu{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding-left: 19px
}

.account-body{
    margin-top: 16px;
    margin-bottom: 16px
}

.pointer{
    cursor: pointer;
}

.arrow-icon{
    
}

.account-menu:hover{
    background-color: #f8f9fb;
}

.menu-bottom{
    position: absolute;
    bottom: 32px
}
.width-bar{
    width: 240px
}

.mt-bottom{
    position: absolute;
    bottom: 40px;
}

.mo-ham-wrap{
    display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 28px;
    height: 24px;
    left: 16px;
    top: 26px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
    height: 8%!important;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
    width: 240px!important
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #fff;
    padding: 2.5em 0 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    height: fit-content!important
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top:0
  }

@media(max-width: 935px){
    .side-nav{
        display: none;
    }

    .top-btn, .web-header{
        display: none;
    }

    .top-logo-wrap{
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .portal-logo-wrap{
        margin-left: 16px
    }

    .mo-ham-wrap{
        display: flex;
        flex-direction: row;
        width: 25%
    }

    .portal-header{
        justify-content: space-between;
    }

    .header-icon-wrap{
        margin-left:0;
        margin-right: 20px
    }

    .header-avatar-wrap{
        align-self: center
    }

    .right-avatar{
        width: fit-content;
        margin-right: 0px
    }

    .account-wrap{
        right: 16px;
    }

    .notification-wrap{
        right: 20px
    }

}

.nav-list-txt{
    position: relative;
}

.beta-badge{
    font-size: 10px;
    position: absolute;
    top: -24px;
    padding: 0px 8px;
    background-color: green;
    color: #fff;
    border-radius: 8px;
}

.beta-badge-mo{
    font-size: 10px;
    position: absolute;
    left: 56px;
    top: 0px;
    padding: 0px 8px;
    background-color: green;
    color: #fff;
    border-radius: 8px; 
}

@media(max-width: 480px){
    .portal-header{
        padding: 8px 0 8px 0
    }

    .portal-logo-wrap{
        height: 48px;
        width: auto;
    }

    .header-avatar, .header-avatar-wrap{
        width: 40px;
        height: 40px;
    }

    .welcome-name{
        font-size: 28px!important;
        font-weight: 400!important;
    }

    .side-nav-group{
        width: 100%
    }
}

@media(max-width: 560px){
    .not-body{
        width: 80vw;
        max-width: 480px;
    }

    .not-bodytxt-wrap{
        font-size: 14px;
        width: 80%
    }
}

.menu-after-icon{
    margin-left: 8px
}

.sub-menu{
    padding-top: 0px;
    padding-bottom: 0px
}

.active-sub{
    // border-bottom: 3px solid #3817c9;
    border-bottom: 3px solid #fff;
}

.sub-menu-txt{
    padding-top: 8px;
    padding-bottom: 8px
}

@media(max-width: 480px){
    .candidate-bar{
        display: none;
    }

    .main-canvas{
        width: 100vw
    }

    .modal-main.show1{
        left: 200%!important
    }

}

.bm-item-list{
    padding-left: 0px;
    padding-right: 0
}

.demo-btn{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.5px;

/* White */

color: #FFFFFF;
padding: 5.5px 9px;
background: #3817C9;
border: 0.5px solid #3817C9;
border-radius: 8px;
margin-top: -7px
}

.demo-btn:hover{
    // background: rgba(54,28,174);
    background: #19297c;
}

.column-list{
    list-style: none;
    text-align: left;
}

.column-list li{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 8px;
border-bottom: 1px solid #3817C9;
padding-bottom: 8px;
}

.mo-demo .demo-btn{
    margin-top: 24px;
    text-align: center;
    width: 290px;
    
}

.mo-demo-btn-wrap{
    margin-left: auto;
    margin-right: auto;
    width: fit-content
}