.form-label{
    width: 174px;
    height: 19px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #0f1237);
    margin-bottom: 8px;
}

.main-form{
    width:100%
}

.btn-full{
    width: 100%;
}

.mb-g{
    margin-bottom: 18px;
}

.signup-form>div{
    width: 100%;
}

.btn-google{
    color: #25271c!important;
    border: 1px solid #e8ecf4!important;
    background-color: transparent!important;
    width: 100%;
    border-radius: 8px !important;
    text-align: center;
    font-size: 18px!important;
    display: flex!important;
    justify-content: center;
    box-shadow: none!important;
}

.btn-google div {
    display: none!important;
}

/* .btn-google:hover{
    color: #fff!important;
    background-color: #f47160!important;
} */

.input-box-up{
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    overflow: visible;
    border-radius: 4px;
    border: 1px solid #c9d6e3;
    padding-left: 1em;
    color: #000;
    cursor:text;
    padding-top: 0!important;
    padding-bottom: 0 !important;
}

.input-box-up:hover{
    border: 1px solid #0f1237 !important;
}

.input-box-up:focus{
    outline:none !important;
    border: 1px solid #0f1237 !important;
}

.input-box-up::placeholder{
    color: #b0becf
}

.form-group{
    display: flex;
    flex-direction: column;
}

.signup-form{
    height: 80%;
    width: 331px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.label-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.check-box{
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    overflow: visible;
    border-radius: 4px;
    border: 1px solid  #c9d6e3;
    margin-left:0;
    margin-bottom:0;
    margin-right:8px;
    cursor: pointer;
}

.checkbox-wrap{
    width: auto;
    height: auto;
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #0f1237);
}

.divide-wrap{
    display: flex;
    flex-direction: row;
    width:100%;
    height:auto;
    justify-content: center;
    align-items: center;
}

.divider{
    width: 30%;
    height: 1px;
    background-color: var(--GB_200, #e3eaf2);
    overflow: visible;
}

.middle-txt{
    width: 40%;
    height: auto;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #0f1237);
    padding-left: 18px;
    padding-right:18px;
}

.title-txt-up{
    width:100%;
    height:auto;
    white-space: pre;
    color: var(--Primary_Blue, #0f1237);
}

.sub-txt-up{
    width: 100%;
    height: 44px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    color: var(--Primary_Blue, #0f1237);
    letter-spacing: 0px;
    line-height: 1.2;
    font-size: 18px;
    margin-bottom:23px
}

.pw-hint-title{
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #0f1237);
}

.check-wrapper{
    height:32px
}

.pw-hints-list{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top:8px;
    margin-bottom:27px;
}

.left-list{
    width:50%
}

.right-list{
    width: 50%;
}

.mb-5{
    margin-bottom: 5px;
}

.list-wrap{
    display: flex;
    flex-direction: row;
    width:161px;
    height:16px;
}

.dot-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:22px;
    overflow: hidden;
}

.dot-icon{
    width:auto;
    height: auto;
    align-self: center;
    margin-left:0;
    margin-right:auto
}

.list-txt{
    width: auto;
    height: auto;
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--GB_600, #607890);
}

.check-box-small{
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    overflow: visible;
    border-radius: 4px;
    border: 1px solid #c9d6e3;
    margin-left:0;
    margin-right:8px;
    margin-bottom:0;
    transform: translateY(2px);
    cursor: pointer;
}

.disclaimer-wrap{
    margin-top: 27px;
    width: 100%;
}

.disclaimer-link{
    color: var(--Primary_Red, #f47160) !important;
    text-decoration: underline;
    cursor: pointer;
}

.error-msg{
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    font-weight: 400;
    font-size: 14px;
    color: var(--Error_Red, #e85959);
    margin-top: 4px;
    text-align: left
}
.mt-45{
    margin-top:45px;
}

.height-auto{
    height: fit-content
}

.input-box-pw{
    overflow: visible;
    color: #000;
    cursor:text;
    outline: none;
    flex-grow: 2;
    padding-left: 1em;
    border:none;
    border-radius: 8px;
    padding-top: 0!important;
    padding-bottom: 0 !important;
    background: #F7F8F9;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}