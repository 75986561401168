.employer-bg{
    /* background-image: url('../../assets/outhire/employer-bg.svg')!important; */
    background:linear-gradient(180deg, #F3D548 0%, rgba(243, 213, 72, 0) 134.2%);
}

.employer-home{
    width: 44%;
    /* top: -110px */
}

.w-496{
    max-width: 496px
}

.employer-main{
    transform: translateX(100px) translateY(-20px)!important;
}

.home-card-employer1{
    margin-left: 32px;
    height: 451px;
    width: auto;
    margin-top: 0
}

.fix-496{
    width: 496px;
}

.home-bold-txt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    letter-spacing: 0.25px;
    
    /* Soft black */
    
    color: #373F41;
    width: 405px;
/* line-height: 1.75; */
}

.employer-img{
    max-width: 462px;
    width: 100%;
    margin-left: 10px;
    margin-top: -10px
    /* margin-right: 80px */
    /* margin-left: auto;
    margin-right: auto; */
}

.p-54{
    padding-top: 54px;
    padding-bottom: 54px
}

.card-txt-title{
    font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft black */

color: #373F41;
margin-bottom:24px
}

.card-txt-body{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 120% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41;
max-width: 262px;
margin-bottom: 24px;
height: 155px;
}

.card-txt-bottom{
    display: flex;
    flex-direction: row;
}

.card-bottom{
    font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
}

.card-list-wrap{
    justify-content: space-between;
}

.mb-64{
    margin-bottom: 64px
}

.flow-img{
    margin-bottom: 48px;
}

.flow-txt{
    max-width: 160px;
    text-align: center;
}

.sans-font{
    font-family: 'Source Sans Pro';
}

.pop-font{
    font-family: 'Poppins';
}

.padding-employer2{
    margin-left: 8.36vw;
}

.text-center{
    text-align: center!important;
}

.ml-5p{
    margin-left: 5vw;
}

.mr-5p{
    margin-right: 5vw
}

.ml-3p{
    margin-left: 3vw;
}

.mr-3p{
    margin-right: 3vw
}

.ml-20{
    margin-left: 20px;
    margin-right: 20px;
}
.no-height{
    height: auto!important;
    margin-bottom: 0px
}

.w-896{
    max-width: 896px;
    margin-left: auto;
    margin-right: auto;
}

.padding-employer2{
    margin-left: 48px
}

.employer-bottom-bg{
    /* background-image: url('../../assets/outhire/over-employer-bg.svg'); */
    background-position-y: 0px;
}

@media (max-width:1280px){
    .employer-home{
        width: 47vw;
        right: 7.6vw;
    }

    .padding-employer2{
        width: 316px
    }

    .ml-5p{
        margin-left: 25px;
    }

    .mr-5p{
        margin-right: 25px
    }

    .card-txt-body{
        max-width: 230px;
        height: 200px
    }

    .flow-txt{
        max-width: 130px;
    }

    .home-title{
        font-size: 34px;
    }
}

@media(min-width: 1281px) {
    .employer-home{
        top: -40px
    }

    .ml-5p{
        margin-left: 46px;
    }

    .mr-5p{
        margin-right: 46px
    }
}

.ptb-0{
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.portal-bg{
    background-image: url('../../assets/outhire/portal-bg.svg');
    background-size: 45%;
    padding-top: 35px;
    padding-bottom: 58px;
    background-position-y: 40px;
}

.map-wrap{
    /* background-image: url('./../../assets/outhire/map-bg.svg'); */
    background-repeat: no-repeat;
    
    margin-left: auto;
    margin-right: auto;
    justify-content: left;
    /* width: 1440px;
    height: 967px; */
    display: block;
    flex-direction: column;
    width: 100%;
    height: fit-content
    
}

.map-img{
     margin-left: 0   ;
}

.map-txt-wrap{
    width: 463px
}

.map-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 48px;
line-height: 72px;
text-align: center;
width: 463px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 40px;
margin-left: auto;
margin-right: auto;
margin-bottom: 64px;
}

.map-txt-wrap{
    align-self: center;
}

.map-body-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 50px;
/* or 208% */

text-align: center;

/* Soft Black */

color: #2F2E41;
}

.difference-center{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 48px;
line-height: 72px;
display: flex;
align-items: center;
text-align: center;
width: 252px;
/* White */

color: #FFFFFF;

margin-left: auto;
margin-right: auto;


}

.difference-center-wrap{
   
    text-align: center;
    width: 100%;
    padding-top: 106px
}


.difference-bg{
    background: linear-gradient(180deg, #1D0EC9 0%, #D047CB 86.98%);
border-radius: 40px;
width: 835px;
height: 763px;
margin-left: auto;
margin-right: auto;
margin-top: 76px;
margin-bottom: 183px;
}

.difference-wrap{
    position: relative;
    width: 1150px;
    margin-left: auto;
margin-right: auto;
}

.difference-card{
    background: #EFE9F4;
border-radius: 40px;
width: fit-content;
padding: 54px 44px;
height: 358px;
}

.diff-card-txt{
    width:260px;
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
margin-top: 24px
}

.bg-transparent{
    background-color: transparent;
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: none;
}

.scroll-canvas{
    margin-top: 72px;
}

.circle{
    width: 50px;
    height: 50px;
    
    /* Deep Purple */
    
    background: #3817C9;
    /* White */
    

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); 
    border-radius: 100%;
    border: 3px solid  #F3D548;
}

.circle:hover{
    border: 3px solid  #fff;
}

.scroll-txt:hover{
    color: #2F2E41;
}

.border-circle{
    border: 3px solid #FFFFFF;
}

.scroll-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 50px;
/* identical to box height, or 208% */

/* Soft Black */

color: #2F2E41;
align-self: center;
/* Soft Black */

color: #2F2E41;

opacity: 0.5;
width: 108px;
}

.active-scroll{
    opacity: 1;
}

.ml-120{
margin-left: 165px
}

.ml-56{
    margin-left: 56px;
}

.ml-118{
    margin-left: 162px;
}

.scroll-bar{
    height: 110px;
    width: 6px;
    background-color: #fff;
    margin-left: 187px
}

.ml-165{
    margin-left: 166px
}

.mtb-3{
    margin-top: 3px
}

.ml-3{
    margin-left: -2px
}

.diff-card-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
}

.mt-16{
    margin-top: 16px
}

.card1{
    position: absolute;
    top: -75px;
    left:0;
    height: 320px;
}

.card2{
    position: absolute;
    top: -75px;
    right:0;
    height: 320px;
}

.card3{
    position: absolute;
    bottom: -110px;
    left:0;
}

.card4{
    position: absolute;
    left: 402px;
    bottom: -160px
}

.card5{
    position: absolute;
    bottom: -110px;
    right:0;
}

.scroll-img{
    width: 300px;
    height: 300px;
}

.scroll-img-txt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

text-align: center;
letter-spacing: 0.15px;

/* Soft black */

color: #373F41;
width: 295px;

}

.carosel-txt-wrap .row{
    width: fit-content
}

.scroll-right{
    margin-left: 213px;
    align-self: center;
}

.carosel-card{
    width: 356px;
    align-self: center;
    margin-left: 20px;
    margin-right: 20px;
}

.inactive-carosel{
    width: 252px;
    opacity: 0.4;
    margin-left: 4px!important;
    margin-right: 4px!important;
    }

.slide{
    width: fit-content!important;
    min-width:auto!important;
    display: flex;
    justify-content: center;
    /* margin-left: 12px!important;
    margin-right: 12px!important; */
}

.carousel .control-dots .dot{
    width: 10px!important;
    height: 10px!important
}

.ml-12{
    margin-left: 12px!important;
}

.mr-12{
    margin-right: 12px!important;
}

.carousel-slider{
    height: 340px
}

.carousel-root{
    height: 330px
}

.carousel .control-dots .dot {
    background: #3817c9!important;
}

.slider{
    width: 924px!important;
    justify-content: space-between;
}

/* .swiper-slide{
    width: fit-content!important
}

.swiper-container{
    height: 350px
} */

/* .swiper-pagination{
    bottom: -20px
} */

.carosel-head{
    background: #3817C9;

/* Inside auto layout */

flex: none;
order: 0;
align-self: center;
flex-grow: 0;
padding: 25px 60px;
border-top-left-radius: 8px;
border-top-right-radius: 8px;

}

.carosel-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    
    text-align: center;
    letter-spacing: 0.15px;
    
    /* White */
    
    color: #FFFFFF; 
}

.carosel-txt-wrap{
    padding: 16px
}

.carosel-card-wap{
    padding-left: 35px;
    padding-right: 35px;
    justify-content: space-between;
}

.carosel-bottom-txt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 40px;
margin-top: 40px;
}



.inactive-carosel .carosel-head{
    padding-left: 40px;
    padding-right: 40px;
}

.inactive-carosel .carosel-txt-wrap{
    width: 231px
}

.inactive-carosel .carosel-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 12.0638px;
line-height: 16px;
/* or 133% */

letter-spacing: 0.335106px;

/* Soft Black */

color: #2F2E41;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.inactive-carosel .carosel-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13.4043px;
line-height: 19px;
/* identical to box height, or 140% */

text-align: center;
letter-spacing: 0.100532px;

/* White */

color: #FFFFFF;
}


.carosel-card{
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.carosel-txt{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: left;
}

.mo-img{
    display: none;
}

 .mo-section {
    display: none;
}

.mo-approach-wrap{
    display: none;
}

/* .mo-map{
    display: none;
} */

.main-body-wrap{
    overflow-x: hidden;
}

.map-body-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.map-txt-wrap{
    width: 100%
}

.map-body-wrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px
}

.map-txt-divider{
    height: 16px;
    width: 1px;
    background-color: #2F2E41;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 4px
}

@media (max-width: 480px){
    .employer-main{
        transform: none!important;
    }

    .map-txt-wrap{
        width: 100%
    }

    .mo-no-bg{
        background-color: transparent;
    }

    .carosel-card-wap{
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .map-wrap{
        background-image: none;
        width: 100%
    }

    .map-img{
        margin-left: auto;
        margin-right: auto;
    }

    .map-title{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

text-align: center;
letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
width: 100%;
margin-bottom: 24px!important
    }

    .map-wrap{
        flex-direction: column;
        height: fit-content;
    }

    .mo-map{
        display: flex;
        height: fit-content;
    }

    .mo-approach-wrap{
        display: block;
    }

    .map-body-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 24px
    }


    .map-txt-divider{
        height: 16px;
        width: 1px;
        background-color: #2F2E41;
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 4px
    }

    .map-body-txt{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
    }

    .approach-item{
        margin-top: 36px;
    }

    .approach-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */
        
        text-align: left;
        letter-spacing: 0.15px;
        
        /* Soft Black */
        
        color: #2F2E41; 
        padding-bottom: 8px;
        border-bottom: 1px solid #3817C9;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
    }


    .approach-txt{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft black */

color: #373F41;
text-align: left;
width: 80vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
    }

    .scroll-img{
        width: 200px;
        height: 200px
    }

    .w-100{
        width: 100%;
        margin-bottom: 24px!important;
    }

    .w-896{
        width: 90%;
        max-width: none;
    }

    .mo-section{
        display: flex;
        justify-content: center;
        background-color: transparent;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .web-section{
        display: none;
    }

    .emloyer-first{
        height: 489px!important;
    }

    .mo-center{
        text-align: center;
    }

    .home-card-employer1{
        display: none;
    }

    .mo-bold-txt{
        /* Body large | Poppins */

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
width: 310px!important;
margin-left: auto;
margin-right: auto;
    }

    .p-54{
        padding-top: 37px;
        padding-bottom: 37px;
    }

    .mo-img{
        display: flex;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        margin-bottom: 24px;
    }

    .employer-img{
        width: 70vw;
        max-width: none!important;
        margin-left: 0;
    }

    .mo-difference-wrap{
        position: relative;
       width: 100%;
        margin-left: auto;
    margin-right: auto;
    }
    
    .mo-difference-card{
        background: #EFE9F4;
    border-radius: 40px;
    width: 85%;
    padding: 24px 16px;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    }

    .diff-card-txt{
        width: 100%
    }

    .diff-icon{
        width: 50px
    }

    .carosel-bottom-txt{
        display: none;
    }

    .web-show{
        display: none;
    }

    .home-sub-title{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

text-align: center;
letter-spacing: 0.15px;

/* Soft black */

color: #373F41;
margin-bottom: 24px;
    }

    .web-map{
        display: none;
    }

    .scroll-canvas{
        display: none;
    }

    .pxy-0{
        padding-left: 0px;
        padding-right: 0px
    }

    .slider{
        width: 100%!important
    }

    .carosel-card{
        margin-left: calc(50vw - 178px);
        margin-right: calc(50vw - 178px);
    }
}

