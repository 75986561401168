
.contact-head-wrap{
    width: 100%;
    text-align: center;
    padding-top: 68px;
}

.contact-title{
    width: 564px;
}

.contact-title-body{
    width: 62vw;
    max-width: 786px;
    margin-left: auto;
    margin-right: auto;
}

.contact-form-wrap{
    background: linear-gradient(250.85deg, #1D0EC9 -9.77%, #D047CB 87.5%);  
    margin-top: 75px;
    padding-top: 75px;
    padding-bottom: 75px;
    margin-bottom: 100px
}

.contact-home-card{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 6.25vw;
    margin-right: 6.25vw; */
    /* width: 87.5vw; */
    /* margin-top: 10vw; */
    padding: 48px 100px
}

.contact-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact-info{
    display: flex;
    flex-direction: column;
    margin-right: 26px;
}

.contact-input{
    background: rgba(209, 79, 255, 0.03);
/* Border */
border: none;
border-bottom: 1px solid #E8ECF4;
outline: none;
/* Button large | SSP */

font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: flex-end;
letter-spacing: 0.75px;
padding: 16px 20px;
width: 331px;
height: 24px;
}

.contact-input::placeholder, .contact-txtarea::placeholder{
    color: rgba(47, 46, 65, 0.5);
}

.contact-txtarea{
    font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: flex-end;
letter-spacing: 0.75px;
padding: 16px 20px;
background: rgba(209, 79, 255, 0.03);
/* Border */
border: 1px solid rgba(56, 23, 201, 0.1);
outline: none;
height: 168px;
width: 519px
}

.btn-submit-home{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.75px;
    width: 331px;
    
}

.txt-left{
    text-align: left;
}

.contact-label{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.75px;

/* Soft Black */

color: #2F2E41;
margin-bottom: 16px;
}

.contact-input{
width: 440px;
}

.mr-24{
    margin-right: 24px
}

.red-star{
    color: rgba(244, 12, 40, 1);
    margin-left: 4px;
}

.contact-row{
    justify-content: space-between;
}

.left-btn{
    text-align: left;
}

.mb-0{
    margin-bottom: 0px
}

.checkbox-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

align-items: center;
letter-spacing: 0.25px;

/* Soft black | 50 */

color: rgba(47, 46, 65, 0.5);
margin-left: 8px;
}

.mt-7{
    margin-top: 7px
}

.d-column{
    display: flex;
    flex-direction: column;
}

@media(max-width: 480px){
    .contact-home-card{
        width: calc(100vw - 24px);
        border-radius: 0px;
        padding: 10px 12px;
    }

    .contact-row{
        flex-direction: column;
    }

    .contact-input{
        width: calc(100% - 40px);
    }

    .contact-txtarea{
        width: calc(100% - 40px);
    }

    .mo-right{
        text-align: right;
    }

    .left-btn .btn-save {
        margin-right: 0px;
        font-family: 'Oxygen';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        height: 54px!important;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}