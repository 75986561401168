.primary-blue{
    color: #0f1237;
  }

.left-container-up{
    width:38%;
    height:100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.right-container-up{
    width:62%;
    height:100vh;
    display: flex;
    flex-direction: column;
    padding-right: 5vw;
    justify-content: center;
}

.logo-wrapper-right{
    width:160px;
    height:auto;
    margin: 42px 36px 0 auto;
    float:right;
}

.title-txt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 51px;
/* identical to box height */

text-align: center;
letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
}

.check-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

color: #000000;
}

.btn-mr{
    margin-left: 24px;
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.75px;

/* White */

color: #FFFFFF;
}

.btn-mr2{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.75px;

/* White */

color: #FFFFFF;
}

.title-txt{
    width: 382px
}

.terms-form{
    width: 382px;
    text-align: left;
}

.px-32{
    padding-left: 32px!important;
    padding-right: 32px!important;
}

.coloum{
    overflow-x: hidden;
}

.pw-hint-title{
    text-align: left;
}
@media (max-width:1440px){
    .right-container-up{
        padding-right: 0;
    }
    .left-container-up{
        padding-left: 0;
    }
}

@media (max-width:976px){
    .right-container-up{
        width:100%;
    }
    .left-container-up{
        display:none
    }
}

.right{
    float: right;
}

.side-img{
    height: 35vw;
    padding-left: 4vw;
    align-self: center;
    width:auto;
    transform:translateX(0.5vw);
}

.mb-12{
    margin-bottom:12px;
}

.signup-container{
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.float-left{
    float: left;
    margin-right: auto;
}

/* STYLES FOR EMAIL CONFIRMATION PAGE */
.bg-light{
    background-color: #faf9fb;;
}

.full-height{
    height: 100vh;
}

.confirm-container{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.confirm-wrap{
    width: 648px;
    height: fit-content;
    box-shadow: 0px 0px 4px 0px #e3eaf2;
    background-color: #fff;
    border-radius: 8px;
    align-self: center;
    padding: 48px 0px 72px 0px;
    text-align: center;
}

.sentimg-wrap{
    width: 173px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.bold-title{
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color:  #0f1237 /* {"name":"Primary Blue"} */;
    font-size: 40px;
    font-weight: 700;
}

.verify-txt{
    color:  #0f1237 /* {"name":"Primary Blue"} */;
    letter-spacing: 0px;
    line-height: 1.2;
    font-size: 18px;
    font-weight: 700;
}

.color-primary{
    color: #0f1237;
}

.verify-email{
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
    font-weight: 500;
    color: var(--Primary_Blue, #0f1237);
    font-size: 20px;
}

.mb-56{
    margin-bottom: 56px;
}

.width-494{
    width: 494px;
    margin-left: auto;
    margin-right: auto;
}

.resend-link{
    cursor: pointer;
    color:  #3817C9 !important;
    font-weight: 500 !important;
}

.resend-link:hover{
    text-decoration: underline;
}

/* For SignUp.js  */
@media (max-width: 540px){
    .signup-form{
        width: 92%!important;
        margin-left: 1rem!important;
        margin-right: 1rem!important;
    }
    .btn-google{
        width: 100%;
    }
    .list-wrap{
        float: left;
    }
    .sub-txt-up{
        height: auto!important;
    }
    .title-txt-up{
        white-space: unset!important;
    }
}
/* For ConfirmEmail.js during account verification with e-mail sign up */
@media (max-width: 680px){
    .confirm-wrap{
        width: 100%;
    }
    .width-494{
        width: 92%;
    }
    /* .verify-email{
        white-space: unset;
        width: 100%;
    } As all e-mails do not have spaces, this does not allow for display of a long e-mail without a different style*/
}

.form-txt{
    width: 100%;
    font-size: 20px;
}
.width-400{
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px
}

.oxygen-400-black{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Soft Black */

    color: #2F2E41;
}

.link-underline{
    color: #134C72;
    text-decoration: underline;

}

.forgetpw{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.25px;

    /* Soft Black */

    color: #2F2E41;
    margin-bottom: 24px;
}

.forgetpw-txt{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    text-align: center;
    letter-spacing: 0.5px;

    /* Soft Black */

    color: #2F2E41;
    margin-bottom: 24px;
    margin-left: 58px;
    margin-right: 57px;
}

.txt-in-wrap{
    width: 100%;
    display: flex;
    white-space: normal!important;
}

.beta-tag{
    content: "beta";
    position: fixed;
    width: 80px;
    height: 25px;
    background: #3817C9;
    top: 7px;
    left: -20px;
    text-align: center;
    font-size: 13px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    line-height: 27px;
    transform:rotate(-45deg);
  }