.about-body-txt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */
    
    letter-spacing: 0.15px;
    
    /* Soft Black */
    
    color: #2F2E41;
width: 573px;
margin-top: 40px
}

.btn-save{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

text-align: center;
letter-spacing: 0.5px;
padding: 8px 20px
}

.mx-0{
    margin-left: 0;
    margin-right: 0;
    width: 80%
}

.ml-10p{
    margin-left: 10%;
    margin-right: 10%
}

.mb-210{
    margin-bottom: 210px;
}

.about-first-wrap{
    width: 100%;
    height: fit-content;
}

.about-diagonal{
    top: -70%;
}

.about-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    letter-spacing: 0.25px;
    
    /* Soft Black */
    
    color: #2F2E41;
}



.about-content-txt{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    /* or 167% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41;
    margin-top: 40px;
    max-width: 854px;
}

.percon-wrap{
    text-align: left;
    width: 25%
}

.person-inner{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.mb-94{
    margin-bottom: 94px;
}

.about-m>.modal>.modal-main{
    border-radius: 0%;
}

.person-img{
    /* border-radius: 4px; */
    cursor: pointer;
    width: 200px;
    height: 343px;
}

/* .pd-about{
    padding-left: 8%;
    padding-right: 8%;
    width: calc(60%);
} */

.person-name{
    font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 1px;
width: 200px;
/* Soft Black */

color: #2F2E41;
}

.person-icon-wrap{
    display: flex;
    margin-top: 8px
}

.person-icon{
    margin-right: 10px;
    align-self: center;
}

.person-row{
    justify-content: center;
    flex-wrap: wrap;

}

.ml-alarm-card{
    text-align: left;
}

.person-modal-content{
    display: flex;
    flex-direction: row;
    position: relative;
}

.s-p{
    width: 105%
}

.person-modal-label{
    position: absolute;
    text-align: left;
    top: 16px;
    left: 16px;
    color: #fff;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    /* or 140% */

    letter-spacing: 0.5px;
    /* White */
    color: #FFFFFF;

}

.modal-person{
    width: auto;
    object-fit: cover;
}

.person-modal-txt-wrap{
    text-align: left;
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 48px;
    margin-bottom: 48px;
    width: 353px
}

.person-modal-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.nav-list-txt{
    color: #2f2e41
}

.person-modal-txt{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41;
width: 353px;

/* Soft Black */

color: #2F2E41;
margin-top: 32px
}

.relative{
    position: relative;
}

.overlap-card{
    z-index: 4;
    margin-top: 120px;
    border: 4px solid rgba(29, 14, 201, 1);
}

.over-lap{
    z-index: 4
}

.about-first-wrap{
    width: 1370px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 70px;
}

.coloum{
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 0
}


.ml-alarm-card{
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    justify-content:flex-start;
}

.w-859{
    max-width: 859px;
    margin-left: auto;
    margin-right: auto
}

.connect-link{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

text-align: center;
letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
}

.alarm-icon{
    align-self: flex-start;
    margin-top: 6px;
    margin-left: 55px
}

.ho-center{
    margin-left: auto;
    margin-right: auto;
    width: 817px
}

.w-70{
    width: 70vw;
    max-width: none;
    margin-left: auto;
    margin-right: auto;
}


.over-diagonal {
    /* Absolute position */
    left: 0px;
    position: absolute;
    top: -52.5%;

    /* Take full size */
    height: 103%;
    width: 100%;

    /* Create diagonal sides */
    transform: skewY(-24.6deg);

    /* Background color */
    background-color: #fff;

    /* Displayed under the main content */
    z-index: 1;
}

.person-img{
    transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .person-inner:hover .person-img {
    opacity: 0.3;
  }
  
  .person-inner:hover .middle {
    opacity: 1;
  }

  .w-673{
    width: 673px
  }

  .txt-tilt{
    font-family: 'Poppins';
font-style: italic;
font-weight: 600;
font-size: 34px;
line-height: 51px;
letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
  }

  .person-name{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41;
}

@media (max-width:1280px){
    .over-diagonal {
        /* Absolute position */
        left: 0px;
        position: absolute;
        top: -55.5%;
    
        /* Take full size */
        height: 90%;
        width: 100%;
    
        /* Create diagonal sides */
        transform: skewY(-28.6deg);
    
        /* Background color */
        background-color: #fff;
    
        /* Displayed under the main content */
        z-index: 1;
    }

    .about-content-txt{
        width: 650px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 32px;
    }

    .w-859{
        max-width: 650px;
    }

    .person-inner{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .person-img{
        width: 100%;
    }

    .person-name{
        font-family: 'Oxygen';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        /* or 150% */
        
        letter-spacing: 0.5px;
        
        /* Soft Black */
        
        color: #2F2E41;
    }

    .about-c .white-poligon{
        top: -21%!important
    }
    
}

@media(max-width: 1440px) and (min-width: 1281px){
    .about-c .white-poligon{
        top: -12%!important
    }
}

.about-c .white-poligon{
    top: -22.5%
}

.terms-card{
    width: 704px;
height: auto;

/* White */

background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 12px;
margin-left: auto;
margin-right: auto;
text-align: left;
padding: 48px;
border: 4px solid;
/* border-image-source: linear-gradient(to bottom, rgba(29, 14, 201, 1), rgba(208, 71, 203, 1));
border-image-slice: 1; */
border: 4px solid rgba(29, 14, 201, 1);
}

.terms-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
}

.terms-body{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.terms-bold{
    font-weight: 700;
}

.long-white{
    top: -180px!important;
    transform: rotate(1deg);
    /* left: -13px!important; */
    width: 100vw!important
}

@media(min-width: 2560px){

    .about-c .white-poligon{
        width: 150vw!important;
        left: -40%!important;
        /* top: -10%!important */
        top: -40%!important
    }
}

.value-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    /* identical to box height */

    letter-spacing: 0.25px;

    /* Soft Black */

    color: #2F2E41;
    margin-bottom: 36px;
    margin-right: 45px;
}

.value-icon-txt{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 51px;
/* identical to box height */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
margin-top: 31px
}

.value-icon{
    height: 125px;

}

.ml-150{
    margin-left: 165px
}

.ml-130{
    margin-left: 130px
}

.value-icons-wrap{
    margin-left: auto;
    margin-right: auto;
    width: 897px;
    justify-content: space-between;


}

.value-body{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 30px;
/* or 208% */

display: flex;
align-items: center;
text-align: center;

/* Soft Black */

color: #2F2E41;
width: 1166px;
margin-left: auto;
margin-right: auto;
margin-top: 42px;
}

.join-title{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
/* identical to box height, or 120% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.join-head{
    /* Headline small | Poppins */

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
}

.join-txt{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
/* or 120% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.w-918{
    width: 918px
}

.ml-join{
    justify-content: flex-start;
    margin-left: 77px
}

.join-icon{
    align-self: flex-start;
    margin-right: 16px;
    margin-top: 2px;
}

.mb-8{
    margin-bottom: 8px
}

.w-770{
    width: 770px
}

.modal-main{
    overflow-y: auto;
}

.right-txt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */
    
    letter-spacing: 0.15px;
    
    /* Soft Black */
    
    color: #2F2E41;
    margin-top: 24px;
    text-align: right;
    margin-right: 32px;
}

.here-link{
    text-decoration: none;
    color: rgba(19, 76, 114, 1);
}

.accordion{
    display: flex;
    flex-direction: column;
    padding: 0px;

}

.accordion__button{
    background-color: transparent!important;
    width: calc(100% - 36px)!important;
    font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
/* or 120% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
}

.accordion__item + .accordion__item, .accordion {
    border-top:none!important;
    border: none!important
}

.pxy{
    padding-left: 24px;
    padding-right: 70px;
}

.accordion__panel{
    padding: 0px!important
}

.faq-txt{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    letter-spacing: 0.5px;
    
    /* Soft Black */
    
    color: #2F2E41; 
    width: 666px;
    margin-left: 45px
}

.accordion__button:before{
    background: url("../../assets/outhire/pull.svg") no-repeat;
    width: 22px!important;
    height: 22px!important;
    content: ""!important;
    border: none!important;
    transform: translateY(4px)!important;
}

.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before{
    transform: rotate(180deg)  translateY(-4px)!important;
}

.accordion__button[aria-expanded='true']{
    /* border-bottom: 0.5px solid #3817C9; */
    /* outline: 1px navy solid; 
  outline-offset: -50px; */

}

/* .bullet-i{
    display: flex;
    width: 30px;
    text-align: right;
} */
.ml-60{
    margin-left: 60px
}

.ml-56{
    margin-left: 56px
}

.ml-32{
    margin-left: 32px
}

.ml-100{
    margin-left: 100px
}

.text-underline{
    text-decoration: underline;
}

.faq-box{
    border-top: 0.5px solid #3817C9;
    padding-top: 8px
}

.ml-0{
    margin-left: 0px
}

.flex-start{
    align-self: flex-start;
    margin-top:8px
}

.modal{
    z-index: 5000
}

@media(max-width: 480px){
    .mo-person-card-wrap{
        width: 92%;
        border-radius: 12px;
    }

    .error-txt{
        width: 100%
    }

    .percon-wrap {
        width: 250px;
        margin-bottom: 40px!important
    }

    .person-modal-label{
        display: none;
    }

    .modal-person{
        display: none;
    }

    .person-modal-txt-wrap{
        width: 299px!important;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .person-modal-txt{
        width: 299px!important
    }

    .a-modal-main{
        border-radius: 8px;
    }

    .person-modal-title{
        width: fit-content
    }

    .weight-modal-close{
        position: absolute;
        z-index: 50;
        right: 0px
    }

    .about-m .modal-main{
        max-height: calc(85vh - 62px);
        border-radius: 8px;
    }

    .input-box-pw{
        width: calc(100% - 84px)
    }

    .mo-full-title{
        width: 320px;
        margin-left: auto;
        margin-right: auto;
    }

    .mo-center{
        text-align: center;
    }

    .mo-first-wrap{
        height: fit-content!important
    }

    .mo-about-card{
        width: 92vw;
        margin-top: 0px
    }

    .mo-ho-center{
        width: calc(100% - 48px);
    }

    .about-content-txt{
        width: 100%;
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
/* or 188% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
margin-top: 24px
    }

   .icon-text-wrap .about-title{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

text-align: center;
letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
width: 273px;
    }

    .alarm-icon{
        display: none;
    }

    .mo-about-card .icon-text-wrap{
        margin-bottom: 0px;
    }

    .ho-center .btn-save {
        margin-right: 0px;
        font-family: 'Oxygen';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        height: 54px!important;
    }

    .value-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */

        letter-spacing: 0.15px;

        /* Soft Black */

        color: #2F2E41;
        margin-right: 0px!important;
        margin-bottom: 24px;
    }

    .value-body{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
width: 340px
    }

    .value-icon{
        height: 58px
    }

    .value-icon img{
        height: 100%
    }

    .value-icon-txt{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */

text-align: center;
letter-spacing: 0.15px;

/* Soft Black */

color: #2F2E41;
margin-top: 16px
    }

    .mb-mo-vicons{
        margin-bottom: 27.5px;
    }

    .ml-36{
        margin-left: 36px
    }

    .line-row .icon-txt{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.75px;

/* Soft Black */

color: #2F2E41;
    }

    .join-txt{
        width: 320px;
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
    }

    .ml-join{
        margin-left: 0px
    }

    .join-title{
        font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41;
    }

    .join-txt-wrap .join-txt{
        width: 290px!important
    }

    .right-txt{
        font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* or 140% */

letter-spacing: 0.15px;
margin-right:auto;
text-align: center;

    }

    .terms-card{
        padding: 48px 16px;
        width: calc(92vw - 32px);
        margin-bottom: 0px;
    }

    .long-white{
        top: -50px!important;
        left: -20px;
        width: 800px!important
    }

    .accordion__button{
        /* Body small | Oxygen */

font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
padding: 8px!important;
width: calc(100% - 16px)!important;
    }

    .faq-txt{
        width: 274px;
        font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

letter-spacing: 0.25px;

/* Soft Black */

color: #2F2E41;
    }

    .mo-mt-0{
        margin-top: 0px
    }

    .mo-ham-wrap .bm-overlay{
        left:0px
    }

    .mo-ham-wrap .bm-menu-wrap{
        right:0px;
        transition: all 0.8s ease 0s!important;
    }
}

