.signin-container{
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-txt{
    width:100%;
    height:auto;
    white-space: pre;
    color: var(--Primary_Blue, #0f1237);
}

.inner-modal-title{
    margin-top: 16px;
    text-align: center;
    width: fit-content;
    margin-left: calc(50% - 43.21px);
}

.inner-modal-txt{
    font-size: 14px;
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px
}

.inner-modal-btn{
    margin-top: 16px;
    margin-right: auto;
    margin-left: auto;
    height: 40px;
    width: 200px;
    margin-bottom: 16px;
}

.sub-txt{
    width: 443px;
    height: 44px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    color: var(--Primary_Blue, #0f1237);
    letter-spacing: 0px;
    line-height: 1.2;
    font-size: 18px;
    margin-bottom:23px
}

.orange-bold{
    font-weight: 500 !important;
    color: var(--Primary_Red, #f47160);
    cursor: pointer;
}

.mb-small{
    margin-bottom:8px
}

.mb-48{
    margin-bottom:48px
}

.signin-wrapper{
    place-self: center;
    z-index: 1;
    width: 443px;
    height: auto; 
    margin: 0 auto;
    align-self: center;
}

.gicon{
    width:27px;
    height:27px;
    margin-right:8px;
    vertical-align: center;
    align-self: center;
    padding-bottom:2px;
}

.btn-label{
    align-self: center;
}

.btn-flex{
    display: flex;
    justify-content: center;
    align-content: center;
}

.mb-16{
    margin-bottom:1em;
}

.divider-or{
    width: 32.5%;
    height: 1px;
    background-color: var(--GB_200, #e3eaf2);
    overflow: visible;
}

.middle-txt-or{
    width: 35%;
    text-align: center;
    height: auto;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 20px;
    color: #0f1237;
    /* padding-left: 18px; */
    /* padding-right:18px; */
}

.btn-full{
    width: 100%;
}

.icon-btn{
    margin-right: 24px;
    align-self: center
}

.icon-btn-txt{
    align-self: center;
}

.btn-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
}