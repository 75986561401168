.form-label{
    width: 174px;
    height: 19px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #0f1237);
}

.input-box{
    box-sizing: border-box;
    width: 331px;
    height: 56px;
    overflow: visible;
    border-radius: 8px;
    border: 1px solid #E8ECF4;
    padding-left: 1em;
    padding-right: 1em;
    color: #000;
    cursor:text;
    background: #F7F8F9;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.input-box:hover{
    border: 1px solid #0f1237 !important;
}

.input-box:focus{
    outline:none !important;
    border: 1px solid #0f1237 !important;
}

.form-group{
    display: flex;
    flex-direction: column;
}

.signin-form{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label-wrap{
    display: flex;
    flex-direction: row;
    width: 444px;
}

.check-box{
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    overflow: visible;
    border-radius: 4px;
    border: 1px solid  #c9d6e3;
    margin-left:0;
    margin-bottom:0;
    margin-right:8px;
    cursor: pointer;
    transform: translateY(2px);
}

.checkbox-wrap{
    width: auto;
    height: auto;
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #0f1237);
}

.divide-wrap{
    display: flex;
    flex-direction: row;
    width:331px;
    height:auto;
    justify-content: space-between;
    align-items: center;
}

.divider{
    width: 30%;
    height: 1px;
    background-color: var(--GB_200, #e3eaf2);
    overflow: visible;
}


.middle-txt{
    width: 40%;
    height: auto;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #0f1237);
    padding-left: 18px;
    padding-right:18px;
}

.form-wrap{
    align-self: center;
    width:331px;
}

.pw-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #E8ECF4;
    background: #F7F8F9;
}

.pw-wrap:hover{
    border: 1px solid #0f1237 !important;
}

.pw-wrap:focus{
    outline:none !important;
    border: 1px solid #0f1237 !important;
}

.show-txt{
    width: auto;
    height: auto;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.2;
    font-size: 14px;
    color: var(--Primary_Blue, #0f1237);
    font-weight: 400;
    padding-right: 1em;
    align-self: center;
    cursor: pointer;
}

.error-wrap{
    width: 100%;
    height: 64px;
    background-color: rgba(244, 113, 96, 0.15);
    overflow: visible;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}

.error-icon-wrap{
    padding-left: 1em;
    padding-right: 1em;
    justify-content: center;
    display: flex;
}

.error-icon{
    align-self: center;
}

.error-txt-wrap{
    justify-content: center;
    display: flex;
}

.error-txt{
    width: 260px;
    height: 30px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    color: #000000;
    letter-spacing: 0px;
    line-height: 1.2;
    font-size: 13px;
    font-weight: 400;
    align-self: center;
}

.resend-link{
    text-decoration: underline;
}

.txt-underline{
    text-decoration: underline !important;
    cursor: pointer;
}

.mt-30{
    margin-top: 30px;
}

.mb-20{
    margin-bottom: 20px;
}
.link-wrap{
    width:100%;
    text-align: center;
}
.signup-link{
    width: auto;
    height: auto;
    overflow: visible;
    white-space: pre;
    line-height: 1.2;
    color: var(--Primary_Red, #00509D);
    cursor: pointer;
}
.signup-link:hover{
    color: #0f1237;
}
.signin-form:focus{
    color: #0f1237;
}

.mt-64{
    margin-top: 64px
}

/* SIGNIN STYLE */
.mb-60{
    margin-bottom: 60px
}

.divider-or{
    width: 112px;
    height: 1px;
    background-color: var(--GB_200, #e3eaf2);
    overflow: visible;
}

.w-sign-351{
    width: 351px
}


.margin-txt-or{
    margin-left: 10px;
    margin-right: auto;
}
.middle-txt-or{
    font-size: 16px;
    color: #6A707C;
    /* margin-left: 12px; */
    margin-right: 12px;
    width: 84px
}

.divide-wrap{
    margin-top: 24px;
    margin-bottom: 24px
}

.height-20px{
    height: 20px!important;
}

@media(max-width: 480px){
    .height-20px{
        height: auto!important;
    }
}

.forgot-link{
    float: right;
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--Primary_Blue, #6a707c);
    text-decoration: none;
    text-align: right;
    cursor: pointer;
}

.forgot-link-wrap{
    width: 331px
}

.title-txt{
    font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 43px;
/* identical to box height */

text-align: center;
letter-spacing: 1.75px;

/* Soft Black */

color: #2F2E41;
}

.font-sans{
    font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.75px;
text-align: left!important;
}

.w-free{
width: fit-content;
margin-right: 8px;
}

.go-sign-icon{
    width: 20px;
    height: 20px;
}

.btn-solid:hover, .btn-solid{
    background-color: rgba(56, 23, 201, 1)!important;
}

.btn-light:hover{
    background-color: #fff;
    color:rgba(56, 23, 201, 1)
}

.mb-small{
    margin-bottom: 0!important;
}

.o-modal-sm-content{
    min-width: 300px;
    padding: 16px 16px
}

.o-center-title{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.bottom-sign-in{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px!important;
    text-align: left;
    letter-spacing: 0.75px!important;
    width: fit-content;
    margin-right: 1px;

    /* Dark Gray */
    color: #6A707C!important;
}

.cursor-on{
    cursor: pointer;
}