@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
/**font**/
.font-medium{
    font-weight:500;
}

.font-regular{
    font-weight: 400;
}

.size-2em{
    font-size:2em;
}

.size-1em{
    font-size:1em;
}

.size-small{
    font-size: 14px;
}

.text-center{
    text-align: center;
}

html, body{
    font-family: "Roboto", sans-serif;
    font-style: normal;
  }

.mb-18{
    margin-bottom:18px
}

.mb-24{
    margin-bottom: 24px;
}

.mt-18{
    margin-top:18px;
}

.mt-16{
    margin-top:1em;
}

.mt-24{
    margin-top:24px;
}

.main-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    overflow-y: auto;
}

.left-container{
    width:50%;
    min-width: 500px;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.left-img{
    margin-top: 32px;
    margin-left: 32px
}

@media (max-width:976px){
    .left-container{
        width:100%;
    }
    .right-container{
        display:none
    }
}

.right-container{
    width:50%;
    height: 100vh;
    /* background-image: url('./../assets/images/dr-bg.svg');
    background-size: cover; */
}

.logo-wrapper{
    width:160px;
    height:auto;
    margin: 24px 36px;
}

.logo{
    width:100%;
    height:auto;
    cursor: pointer;
}
/* For SignIn.js */
@media (max-width: 500px){
    .signin-form{
        width: 100%;
    }
    .form-wrap{
        width: 92%!important;
    }
    .left-container{
        min-width: unset;
    }
    .input-box{
        width: 100%!important;
    }
    .btn-regular{
        width: 100%;
    }
}

.sign-diagonal {
    /* Absolute position */
    left: 0px;
    position: absolute;
    top: -52.5%;

    /* Take full size */
    height: 103%;
    width: 100%;

    /* Create diagonal sides */
    transform: skewY(-24.6deg);

    /* Background color */
    background-color: #fff;

    /* Displayed under the main content */
    z-index: 1;
}

@media (max-width:1280px){
    .sign-diagonal {
        /* Absolute position */
        left: 0px;
        position: absolute;
        top: -46.5%;
    
        /* Take full size */
        height: 85%;
        width: 100%;
    
        /* Create diagonal sides */
        transform: skewY(-30.6deg);
    
        /* Background color */
        background-color: #fff;
    
        /* Displayed under the main content */
        z-index: 1;
    }
}

/* .input-box, .input-box-pw{
    background: rgba(209, 79, 255, 0.05)!important;
    border-top: none!important;
    border-right: none!important;
    border-left:  none!important;
    border-bottom: 1px solid rgba(232, 236, 244, 0.1)!important;
} */

.btn-solid{
    background: #3817C9;
border-radius: 8px;
font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.75px;

/* White */

color: #FFFFFF;
}

.btn-light{
    border: 1px solid #3817C9;
border-radius: 8px;
font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.75px;

/* Soft Black */

color: #2F2E41;
}

.forgot-link{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px!important;
text-align: right;
letter-spacing: 0.75px!important;

/* Dark Gray */

color: #6A707C!important;
}

.middle-txt-or{
    font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.75px;

/* Dark Gray */

color: #6A707C!important;
}

.error-txt{
    text-align: left;
}

@media(max-width: 480px){
    .home-login-wrap .title-txt{
        font-size: 24px!important;
        line-height: 35px;
    }

    .forgot-link{
        width: 270px!important;
        font-family: 'Oxygen';
        font-style: normal;
        font-weight: 700;
        font-size: 10px!important;
        line-height: 13px!important;
        /* identical to box height */
        letter-spacing: 0.5px;

        /* Dark Gray */

        color: #6A707C;
    }

    .bottom-sign-in{
        font-weight: 700;
        font-size: 10px!important;
        line-height: 13px!important;
        /* identical to box height */

        letter-spacing: 0.5px;

        /* Dark Purple */

        color: #585481;
    }

    .w-sign-351{
        width: 270px!important;
        justify-content: center;
    }

    .home-login-wrap .divide-wrap{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
    }

    .pw-hints-wrap .list-txt{
        font-size: 10px!important
    }

    .terms-form{
        width: 100%!important
    }

    .btn-mr-mo{
        margin-left: 0px!important
    }

    .forgetpw-txt{
        margin-left: 0px!important;
        margin-right: 0px!important
    }

    .pw-hints-list .list-txt{
        font-size: 10px!important
    }
}