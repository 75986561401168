.container{
  /* font-family: 'Source Sans Pro'; */
  font-family: 'Poppins';
}

.side-bar{
 width: 240px;
 height: 100vh;
}

.font-sm{
  font-size: 12px!important;
}

.justify-left{
    justify-content: left!important;
}

.menu-logo{
    width: 162px;
    height: auto
}

.menu-logo-wrap{
    width: 100%;
    height: 63px;
    text-align: center;
    padding: 8px 0px;
    margin-top:8px
}

.side-menu{
    position: absolute;
width: 240px;
height: calc(100vh - 88px);
 /* background-image: url('../../assets/outhire/bg-admin.svg'); */
background: linear-gradient(180deg, #1D0EC9 0%, #D047CB 86.98%);
background-color: #fff;
border-radius: 0px;
background-repeat: no-repeat;
}

.main-canvas{
    width: calc(100vw - 240px);
    /* background: rgba(239, 233, 244, 0.2); */
    background: #f7f8f9;
    background-image: url('../../assets/outhire/portal-bg.svg');
    
    height: 100vh;
    overflow-y: auto;
}

.pure-canvas{
  background-image: none;
}

.menu-list{
    display: flex;
    flex-direction: row;
    padding: 24px 40px 16px 40px;
    cursor: pointer;
    margin-bottom: 4px
}

.icon-wrap{
    width: 20px;
    height: auto
}

.menu-icon{
    width: 100%;
}

.menu-txt{
    color: #fff;
    margin-left: 16px;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px
}

.menu-list:hover, .active-menu{
    background-color: rgba(105, 80, 215);
    /* background-color: rgba(243,213, 72, 0.05); */
    /* background-color: rgba(56, 23, 201, 0.05); */
}

.top-sec{
    margin-top: 24px;
    margin-left: 36px;
    display: flex;
    flex-direction: row;
    width: calc(100% - 36px)
}

.wel-title{
    font-weight: 600;
font-size: 24px;
line-height: 32px;
color: #2F2E41;
}

input:invalid {
  border: solid red 1px;
}

.avatar-wrap{
    margin-top: 20px;
    background-color: #DB48F3;
    /* background-color: #3817C9; */
    width: 157px;
    height: 157px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    /* background-image: url('../../assets/outhire/avatar_wrap.png'); */
    
}

.avatar{
    margin-top: 12px;
    margin-left: 8px;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}


.avatar-wrap-round{
  margin-top: 20px;
  background: linear-gradient(188.9deg, #3817C9 -4.18%, #D047CB 83.29%);
  /* background-color: #3817C9; */
  width: 150px;
  height: 150px;
  border-radius: 100%;
  /* background-image: url('../../assets/outhire/avatar_wrap.png'); */
  
}

.avatar-rount{
  margin-top: 4px;
  margin-left: 4px;
  width: 142px;
  height: 142px;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.top-name-wrap{
    margin-left: 32px;
    margin-top: 32px;
    
}

.avatar-edit-icon{
  width: 14px;
  height: 14px;
  margin-top: 32px;
  cursor: pointer
}

.top-name{
   color:  #2F2E41;
   font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.5px;
margin-top: 16px;
}

.row{
    display: flex;
    flex-direction: row;
}

.card-wrap{
    margin-left: 36px;
    width: calc(40% - 80px);
    min-width: 440px;
}

.bottom-sec{
    margin-top: 48px;
    display: flex;
    flex-direction: row;
}

.card-wrap-full{
  /* width: 100%;
  width: calc(); */
  padding-left: 34px;
  width: calc(80% - 120px);
  min-width: 918px;
}

.card{
    width: calc(100% - 32px);
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px
}

.card-head{
    display: flex;
    flex-direction: row;
    margin-bottom: 16px
}

.edit-icon{
    cursor: pointer;
}

.cursor{
  cursor: pointer;
}

.btn-edit-avatar{
  font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 10px!important;
line-height: 13px!important;
width: 78px!important;
/* identical to box height */

letter-spacing: 0.5px;

/* Soft Black */

color: #2F2E41!important;
}

.edit-wrap{
    margin-left: auto;
    margin-right: 0
}

.select-img-input{
  display: none;
}

.card-title{
    font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.5px;
}

.info-group{
    margin-top: 8px;
}

.bell-icon{
  cursor: pointer;
  margin-top: 8px
}

.info-label{
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height */
  
  letter-spacing: 0.5px;
  
  /* Soft Black */
  
  color: #2F2E41;
margin-bottom: 10px;
margin-top: 16px
}

.info-txt{
    font-weight: 400;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.25px;
color: #2F2E41;
margin-bottom: 8px
}

.info-divider{
    border-bottom: 0.25px solid #eaebec;
}

.main-canvas::-webkit-scrollbar{
    width: 8px;
}

.main-canvas::-webkit-scrollbar-thumb{
  background: rgba(47, 46, 65, 0.5);
    border-radius: 20px;
}

.resume-wrap{
    display: flex;
    flex-direction: row;
}

.dl-link{
   margin-left: 32px; 
   font-weight: 700;
font-size: 12px;
line-height: 15px;
letter-spacing: 0.5px;
text-decoration-line: underline;
color: #3B32EA;
margin-top: 3px
}

.btn-account{
background: #FFFFFF;
border: 0.5px solid #3817C9;
border-radius: 5px;
padding: 8px;
margin-right: 24px;
}

.btn-save{
    background: #3817C9;
    border: 0.5px solid #3817C9;
border-radius: 12px;
padding: 10px;
margin-right: 16px;
width: 130px;
padding-left: 0px!important;
padding-right: 0px!important;
text-align: center;
color: #fff;
font-weight: 700;
cursor: pointer;
}

.btn-cancel{
    background: #fff;
    border: 0.5px solid #3817C9;
border-radius: 12px;
padding: 10px;
margin-right: 16px;
width: 130px;
font-family: 'Oxygen';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.75px;

/* Deep Purple */

color: #3817C9!important;
cursor: pointer;
}

.sm-avatar-wrap{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    margin-left: 16px;
    background: linear-gradient(188.9deg, #3817C9 -4.18%, #D047CB 83.29%);
    border-radius: 100%;
}

.sm-avatar{
    width: 42px;
    height: 42px;
    border-radius: 100%;
    object-fit: cover;
    margin-top: 4px;
    margin-left: 4px
}

.top-right{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 40px
}

.ml-8{
    margin-left: 8px
}

.o-modal-content{
    padding: 20px 32px;
    min-width: 400px
}

.o-modal-title{
    font-weight: 500;
font-size: 24px;
line-height: 32px;
color: #2F2E41;
margin-bottom: 24px
}

:root {
    --main-color: #3817C9;
    --main-color-hover: rgb(104, 117, 217, 0.2);
    --track-color: rgba(56, 23, 201, 0.21);
  /* Default mark color*/
    --mark-color: #aaaaaa;
  }
  .customSlider-mark {
    cursor: pointer;
    top: 8px;
    width: 1px;
    height: 4px;
    background-color: #3817C9;
  }

  .customSlider {
    /* max width of your slider */
    width: 100%;
    /* Optional: Only If you want to center your slider in the page */
    margin: auto;
  }
  
  .customSlider-track {
    /* Top value to align your track to the center of your thumb */
    top: 8px;
    /* thickness of the track */
    height: 4px;
   /* default color of your track */
    background: var(--track-color);
  }
  
  .customSlider-track.customSlider-track-0 {
    /* color of the track before the thumb */
    background: var(--main-color);
  }

  .customSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background: #fff;
    border: 1px solid #3817C9;
    /* shape of the thumb: circle */
    width: 12px;
    height: 12px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
    margin-top: 2px
  }

  
  .customSlider-thumb1 {
    /* cursor: pointer; */
    /*color for the thumb */
    background: #fff;
    border: 1px solid #3817C9;
    /* shape of the thumb: circle */
    width: 12px;
    height: 12px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
    margin-top: 2px
  }
  
  .customSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
  }

  .mark-hint{
    display: flex;
    flex-direction: row;
    margin-top: 38px
  }

  .mark-hint-txt{
height: 10px;

font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 10px;
/* identical to box height */
display: flex;
align-items: center;
/* Soft Black */
color: #2F2E41; 
  }

  .w-4{
    width: 24%
  }

  .mt-1{
    margin-top: 8px
  }

  .ml-5{
    margin-left: 60%
  }

  .mb-20{
    margin-bottom: 20px
  }

  .mb-24{
    margin-bottom: 24px
  }

  .modal{
    z-index: 100
  }

  .dropzone{
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 21.3446px 94.7702px;

width: 409.82px;
height: fit-content;

/* White */

background: #FFFFFF;
/* Soft black | 50 */

border: 0.853786px dashed rgba(47, 46, 65, 0.5);
border-radius: 10.2454px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin-top: 28px;
text-align: center;
font-size: 14px;
font-weight: 400;
line-height: 20px;
/* or 143% */

text-align: center;
letter-spacing: 0.25px;

/* Soft black | 50 */

color: rgba(47, 46, 65, 0.5);
cursor: pointer;
  }

  .upload-icon{
    margin-top: 40px
  }

  .mb-32{
    margin-bottom: 32px
  }
 

  .modal-btn-wrap{
    width: fit-content;
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
  }

  .mr-20{
    margin-right: 20px
  }

  .box-link{
    cursor: pointer;
    font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;

/* Navy */

color: #19297C;
  }

  .date-input-row{
    display: flex;
    flex-direction: row;
  }

  .date-input{
    /* background-color: rgba(209, 79, 255, 0.05); */
    padding: 10px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border: 1px solid #3817C9;
    outline: none;
    border-radius: 20px;
  }

  .month-input{
    width: 40px;
    margin-right: 16px
  }

  .year-input{
    width: 55px
  }

  .date-divider{
    width: 12px;
height: 0px;

/* Soft Black */

border: 1px solid #2F2E41;
align-self: center;
margin-left: 8px;
margin-right: 8px
  }

  .center-check{
    align-self: center;
    margin-left: 32px;
    
  }

  input[type="checkbox" i]{
    margin-top: 0!important
  }


  .float-right{
    margin-left: auto;
    margin-right: 0;
    width: fit-content;
  }

  .mt-32{
    margin-top: 32px
  }

  .mr-0{
    margin-right: 0!important
  }

  .link{
    cursor: pointer;
    color: #19297C!important
  }

  .files-wrap{
    width: 100%;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .file-wrap{
    width: calc(100% - 20px);
    background: #FFFFFF;
/* Soft black | 50 */

border: 0.827586px solid rgba(47, 46, 65, 0.5);
border-radius: 9.93103px;
padding: 10px;
  }

  .s-icon-wrap{
    width: 20px;
    margin-right: 20px
  }

  .s-icon{
    width: 100%;
  }

  .file-txt-wrap{
    width: 80%
  }

  .file-title-wrap{
    margin-bottom: 8px
  }

  .file-txt{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;

/* Soft black | 50 */

color: rgba(47, 46, 65, 0.5);


/* Inside auto layout */

flex: none;
order: 0;
  }

  .txt-right{
    margin-left: auto;
    margin-right: 0
  }

  .progress-wrap{
    /* border: 4.13793px solid rgba(47, 46, 65, 0.5); */
  
    
  }

  .file-upload-progress{
    background-color: rgba(47, 46, 65, 0.5);
    border-radius: 8px;
    height: 6px
  }

  .bar-completed{
    background-color: #3817C9;
    border-radius: 8px;
    height: 6px
    
  }

  .bar-label{
    display: none;
  }

  .btn-save:disabled{
    opacity: 0.5;
  }

  .success-txt{
    color: #5cb85c;
    font-size: 14px
  }

  .top-left{
    min-width: 452px;
  }

  .top-left-can{
    width: 543px;
  }

  .mo-ham-wrap{
    display: none;
    width: 20px;
    height: 17px;
    align-self: center
  }

  .d-column{
    display: flex;
    flex-direction: column;
  }

  .priority-txt{
    /* Body small | Oxygen */

font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* or 143% */

text-align: center;
letter-spacing: 0.25px;

color: #000000;
text-align: center;
  }

  @media(max-width: 480px){
    .bottom-sec{
      flex-wrap: wrap;
      padding-top: 30px
    }

    .box-wrap{
      width: 100%!important;
      margin-left: auto;
      margin-right: auto;
      padding-left: 12px
  }

  .mo-box{
    width: 130px!important;
    font-size: 10px!important;
    margin-left: 4px!important;
    margin-right: 4px!important;
  }

  .modal-main{
    max-height: 90vh!important;
  }

  .mo-mt-8{
    margin-top: 8px
  }

  .mo-mr-0{
    margin-right: 0px
  }

  .mo-btn-center{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between!important;
  }

  .smaller-txt{
    width: 100px!important;
  }

    .card-wrap{
      padding-left: 22px;
      padding-right: 22px;
      width: calc(100vw - 44px)!important;
      min-width: auto;
      margin: 0
    }

    .top-left{
      display: none;
    }

    .top-right{
      margin-right: 19px
    }

    .sm-avatar-wrap{
      margin-left: 0px;
      width: 50px;
      height: 50px
    }

    .mo-ham-wrap{
      display: block;
    }

    .mo-ham{
      width: 20px!important;
      height: 17px!important;
      align-self: center;
      top: 36px!important;
      left: 22px!important
    }

    .top-sec{
      margin-left: 0px;
      width: 100%;
      position: fixed;
      background-image: url('../../assets/outhire/portal-bg.svg');
      background-color: #f7f8f9;
      margin-top: 0;
      padding-top: 16px;
      padding-bottom: 8px
    }

    .bm-menu-wrap{
      width: 240px!important;
    }

    .w-close-icon{
      width: 18px!important;
      height: 18px!important
    }

    .bm-menu{
      overflow-y: hidden!important;
      height: calc(100vh - 5em);
      overflow: hidden;
    }

    .bm-menu{
      position: relative;
    }

    .menu-logo-wrap{
      position: absolute;
      top: 0
    }

    .side-menu{
      margin-top: 16px;
      height: calc(100vh - 77px)!important
    }
/* 
    .bm-burger-button{
      width: 20px!important;
      height: 17px!important;
      align-self: center
    } */

    .modal-main{
      width: 90%!important;
    }

    .o-modal-content{
      padding: 15px 20px;
      min-width: auto;
      width: calc(100% - 40px)
    }

    .o-modal-title{
      font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 0.5px;
    }

    .info-group{
      width: 100%
    }

    .dropzone{
      width: 100%
    }

    .upload-icon{
      margin-top: 16px
    }

    .upload-hint{
      font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
/* or 143% */

text-align: center;
letter-spacing: 0.186937px;

/* Soft black | 50 */


    }

    .month-input{
      width: 40px;
      height: 20px;
      padding: 4px 8px
    }

    .year-input{
      width: 45px;
      height: 20px;
      padding: 4px 8px
    }

    .date-input-row{
      flex-wrap: wrap;
    }

    .center-check{
      margin-left: 0;
      margin-top: 16px
    }

    .center-check div{
      font-family: 'Oxygen';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: 0.25px;
    }

    .card-title{
      font-size: 14px!important
    }

    .btn-save, .btn-cancel{
      font-size: 12px!important;
      height: 40px;
      text-align: center;
    }

    .bottom-sec{
      height: auto;
    }
    
  }


